import React, { useState, useRef } from 'react';

type TextElement =
    | { type: 'text'; content: string; id: number }
    | { type: 'placeholder'; correctWord: string; id: number }
    | { type: 'lineBreak'; id: number };

interface FillInTheBlanksViewProps {
    text: string;
}

const FillInTheBlanksView: React.FC<FillInTheBlanksViewProps> = ({ text }) => {
    const parseText = (text: string): TextElement[] => {
        const elements: TextElement[] = [];
        let currentText = '';
        let isInPlaceholder = false;
        let currentPlaceholderText = '';
        let placeholderIndex = 0;
        let idCounter = 0;

        for (let index = 0; index < text.length; index++) {
            const character = text[index];

            if (character === '[') {
                if (currentText !== '') {
                    elements.push({ type: 'text', content: currentText, id: idCounter++ });
                    currentText = '';
                }
                isInPlaceholder = true;
                currentPlaceholderText = '';
            } else if (character === ']') {
                elements.push({
                    type: 'placeholder',
                    correctWord: currentPlaceholderText,
                    id: idCounter++, // Use numeric id
                });
                isInPlaceholder = false;
            } else if (character === '\n') {
                if (isInPlaceholder) {
                    currentPlaceholderText += character;
                } else {
                    if (currentText !== '') {
                        elements.push({ type: 'text', content: currentText, id: idCounter++ });
                        currentText = '';
                    }
                    elements.push({ type: 'lineBreak', id: idCounter++ }); // Use numeric id
                }
            } else {
                if (isInPlaceholder) {
                    currentPlaceholderText += character;
                } else {
                    currentText += character;
                }
            }
        }

        if (currentText !== '') {
            elements.push({ type: 'text', content: currentText, id: idCounter++ });
        }

        return elements; // IDs remain numbers
    };

    const elements = parseText(text);
    const [userInputs, setUserInputs] = useState<{ [key: number]: string }>({});
    const placeholderIds = elements.filter((el) => el.type === 'placeholder').map((el) => el.id);
    const inputRefs = useRef<{ [key: number]: HTMLInputElement | null }>({});

    const handleInputChange = (index: number, value: string) => {
        setUserInputs((prevInputs) => ({
            ...prevInputs,
            [index]: value,
        }));

        const element = elements.find(
            (el) => el.type === 'placeholder' && el.id === index
        ) as { type: 'placeholder'; correctWord: string; id: number } | undefined;

        if (element) {
            if (value.trim().toLowerCase() === element.correctWord.trim().toLowerCase()) {
                const currentIndex = placeholderIds.indexOf(index);
                if (currentIndex >= 0 && currentIndex < placeholderIds.length - 1) {
                    const nextId = placeholderIds[currentIndex + 1];
                    inputRefs.current[nextId]?.focus();
                }
            }
        }
    };

    const checkInput = (index: number, input: string): boolean => {
        const element = elements.find(
            (el) => el.type === 'placeholder' && el.id === index
        ) as { type: 'placeholder'; correctWord: string; id: number } | undefined;
        if (element) {
            return input.trim().toLowerCase() === element.correctWord.trim().toLowerCase();
        }
        return false;
    };

    return (
        <div>
            {elements.map((element) => {
                if (element.type === 'text') {
                    return <span key={element.id}>{element.content}</span>;
                } else if (element.type === 'placeholder') {
                    const userInput = userInputs[element.id] || '';
                    if (checkInput(element.id, userInput)) {
                        return (
                            <span key={element.id} style={{ fontWeight: 'bold', color: 'green' }}>
                                {element.correctWord}
                            </span>
                        );
                    } else {
                        return (
                            <input
                                key={element.id}
                                ref={(el) => (inputRefs.current[element.id] = el)}
                                type="text"
                                value={userInput}
                                onChange={(e) => handleInputChange(element.id, e.target.value)}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        const currentIndex = placeholderIds.indexOf(element.id);
                                        if (currentIndex >= 0 && currentIndex < placeholderIds.length - 1) {
                                            const nextId = placeholderIds[currentIndex + 1];
                                            inputRefs.current[nextId]?.focus();
                                        }
                                    }
                                }}
                                style={{ width: '100px', marginRight: '5px' }}
                            />
                        );
                    }
                } else if (element.type === 'lineBreak') {
                    return <br key={element.id} />;
                } else {
                    return null;
                }
            })}
        </div>
    );
};

export default FillInTheBlanksView;

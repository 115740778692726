// SentencesCheckView.tsx
import React from 'react';
import { EditSentenceView } from './EditSentenceView';
import {CreateExerciseState} from "./CreateExerciseState";
import {ExerciseCreationType, ExerciseSentence} from "../../../../domain/models/ExerciseCreate";

interface SentencesCheckViewProps {
    sentences: ExerciseSentence[];
    setSentences: (sentences: ExerciseSentence[]) => void;
    selectedType: ExerciseCreationType;
    setSelectedType: (type: ExerciseCreationType) => void;
    state: CreateExerciseState;
    convert: () => void;
}

export const SentencesCheckView: React.FC<SentencesCheckViewProps> = ({
                                                                          sentences,
                                                                          setSentences,
                                                                          selectedType,
                                                                          setSelectedType,
                                                                          state,
                                                                          convert,
                                                                      }) => {
    const handleDelete = (id: string) => {
        setSentences(sentences.filter((sentence) => sentence.id !== id));
    };

    return (
        <div>
            {sentences.length === 0 ? (
                <div>Loading...</div>
            ) : (
                <div style={{ overflowY: 'auto' }}>
                    {sentences.map((sentence) => (
                        <EditSentenceView
                            key={sentence.id}
                            sentence={sentence}
                            setSentence={(updatedSentence) => {
                                setSentences(
                                    sentences.map((s) => (s.id === sentence.id ? updatedSentence : s))
                                );
                            }}
                            onDelete={() => handleDelete(sentence.id)}
                        />
                    ))}
                </div>
            )}
            <div>
                <label>Type</label>
                <select
                    value={selectedType}
                    onChange={(e) => setSelectedType(e.target.value as ExerciseCreationType)}
                >
                    <option value={ExerciseCreationType.MissingWord}>Missing Word</option>
                    <option value={ExerciseCreationType.SelectOption}>Select Option</option>
                </select>
            </div>
            {state === CreateExerciseState.Converting ? (
                <div>Converting...</div>
            ) : (
                <button onClick={convert}>Convert</button>
            )}
        </div>
    );
};

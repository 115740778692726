// ui/components/learning/modes/multiple_choice/MultipleChoiceLearnView.tsx
import React from 'react';
import { MultipleChoiceView } from './MultipleChoiceView';
import { LearningMode, WordItem } from '../../../../../domain/models';
import { useMultipleChoiceLearnViewModel } from './MultipleChoiceLearnViewModel';

interface MultipleChoiceLearnViewProps {
    wordItems: WordItem[];
    markAsKnown: (word: WordItem, mode: LearningMode, success: boolean) => void;
    nextWord: () => void;
}

export const MultipleChoiceLearnView: React.FC<MultipleChoiceLearnViewProps> = ({
                                                                                    wordItems,
                                                                                    markAsKnown,
                                                                                    nextWord,
                                                                                }) => {
    const viewModel = useMultipleChoiceLearnViewModel(wordItems, markAsKnown, nextWord);

    return (
        <MultipleChoiceView
            words={viewModel.multipleChoiceWords}
            options={viewModel.multipleChoiceOptions}
            correctTranslations={viewModel.correctTranslations}
            selectedWord={viewModel.selectedWord}
            selectedOptions={viewModel.selectedOptions}
            isCorrect={viewModel.isCorrect}
            onSelectWord={viewModel.handleSelectWord}
            onSelectOption={viewModel.handleSelectOption}
            onNextButtonPress={viewModel.goToNext}
            onKeyPress={viewModel.handleKeyPress}
        />
    );
};

// ui/components/learning/modes/multiple_choice/MultipleChoiceLearnViewModel.ts
// useMultipleChoiceLearnViewModel.ts
import { useState, useEffect, useCallback } from 'react';
import { LearningMode, WordItem } from '../../../../../domain/models';

interface MultipleChoiceLearnViewModel {
    selectedWord: string | null;
    selectedOptions: { [key: string]: string };
    multipleChoiceWords: string[];
    multipleChoiceOptions: string[];
    isCorrect: boolean | null;
    showWordInForeignLanguage: boolean;
    correctTranslations: { [key: string]: string };
    handleSelectWord: (word: string) => void;
    handleSelectOption: (option: string) => void;
    handleKeyPress: (key: number) => void;
    goToNext: () => void;
}

export function useMultipleChoiceLearnViewModel(
    wordItems: WordItem[],
    markAsKnown: (word: WordItem, mode: LearningMode, success: boolean) => void,
    nextWord: () => void
): MultipleChoiceLearnViewModel {
    const [selectedWord, setSelectedWord] = useState<string | null>(null);
    const [selectedOptions, setSelectedOptions] = useState<{ [key: string]: string }>({});
    const [multipleChoiceWords, setMultipleChoiceWords] = useState<string[]>([]);
    const [multipleChoiceOptions, setMultipleChoiceOptions] = useState<string[]>([]);
    const [isCorrect, setIsCorrect] = useState<boolean | null>(null);
    const [showWordInForeignLanguage] = useState<boolean>(Math.random() < 0.5);
    const [correctTranslations, setCorrectTranslations] = useState<{ [key: string]: string }>({});

    useEffect(() => {
        setupWords();
    }, [wordItems]);

    function setupWords() {
        const words = wordItems.map(item => showWordInForeignLanguage ? item.word : item.translation);
        const options = wordItems
            .map(item => (showWordInForeignLanguage ? item.translation : item.word))
            .sort(() => Math.random() - 0.5);
        const translations = Object.fromEntries(
            wordItems.map(item => [
                showWordInForeignLanguage ? item.word : item.translation,
                showWordInForeignLanguage ? item.translation : item.word,
            ])
        );
        setMultipleChoiceWords(words);
        setMultipleChoiceOptions(options);
        setCorrectTranslations(translations);
        setSelectedOptions({});
        setIsCorrect(null);
        setSelectedWord(null);
    }

    function handleSelectWord(word: string) {
        setSelectedWord(word);
    }

    function handleSelectOption(option: string) {
        if (selectedWord) {
            setSelectedOptions(prev => ({ ...prev, [selectedWord]: option }));
            setSelectedWord(null);

            if (correctTranslations[selectedWord] !== option) {
                setIsCorrect(false);
            }

            if (Object.keys(selectedOptions).length + 1 === multipleChoiceWords.length) {
                selectionFinished();
            }
        }
    }

    function selectionFinished() {
        const allCorrect = Object.entries(selectedOptions).every(
            ([word, option]) => correctTranslations[word] === option
        );
        setIsCorrect(allCorrect);
        if (allCorrect) {
            saveResults();
            nextWord();
        }
    }

    function saveResults() {
        Object.entries(selectedOptions).forEach(([word, selectedOption]) => {
            const item = wordItems.find(item => {
                return showWordInForeignLanguage ? item.word === word : item.translation === word;
            });
            if (item) {
                const correctOption = correctTranslations[word];
                const success = selectedOption === correctOption;
                markAsKnown(item, LearningMode.MultipleChoice, success);
            }
        });
    }

    function goToNext() {
        saveResults();
        nextWord();
    }

    const handleKeyPress = useCallback(
        (key: number) => {
            console.log("handleKeyPress: ", key)
            if (isCorrect !== null) return;
            if (!selectedWord && key <= multipleChoiceWords.length) {
                const word = multipleChoiceWords[key - 1];
                if (!selectedOptions[word]) {
                    handleSelectWord(word);
                }
            } else if (selectedWord && key <= multipleChoiceOptions.length) {
                const option = multipleChoiceOptions[key - 1];
                if (!Object.values(selectedOptions).includes(option)) {
                    handleSelectOption(option);
                }
            }
        },
        [isCorrect, selectedWord, multipleChoiceWords, multipleChoiceOptions, selectedOptions]
    );

    return {
        selectedWord,
        selectedOptions,
        multipleChoiceWords,
        multipleChoiceOptions,
        isCorrect,
        showWordInForeignLanguage,
        correctTranslations,
        handleSelectWord,
        handleSelectOption,
        handleKeyPress,
        goToNext,
    };
}

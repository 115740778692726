// src/compositionRoot.ts

import { WordParser } from './domain/interfaces/WordParser';
import {OpenAITextParser} from "./data/implementations/OpenAITextParser";
import {AuthService} from "./domain/interfaces/AuthService";
import {UserStorage} from "./domain/interfaces/UserStorage";
import AppUserManager from "./domain/services/AppUserManager";
import FirebaseAuthService from "./data/implementations/FirebaseAuthService";
import FirestoreUserService from "./data/implementations/FirestoreUserService";
import MockWordsService from "./data/mocks/MockWordsService";
import FirestoreWordsService from "./data/implementations/FirestoreWordsService";
import {WordsServiceProtocol} from "./domain/interfaces/WordsServiceProtocol";
import {
    ExerciseCreateInteractor,
    MockExerciseCreateInteractor,
    RealExerciseCreateInteractor
} from "./domain/interfaces/ExerciseCreateInteractor";
import {SaveExerciseInteractor, SaveExerciseInteractorImpl} from "./domain/interfaces/SaveExerciseInteractor";
import {ExercisesStorage} from "./domain/interfaces/ExercisesStorage";
import {FirestoreExercisesService} from "./data/implementations/FirestoreExercisesService";
import {PracticeSessionStorage, PracticeSessionStorageStub} from "./domain/interfaces/PracticeSessionStorage";
import {FirestorePracticeSessionService} from "./data/implementations/FirestorePracticeSessionService";
import {FillBlanksStorage} from "./domain/interfaces/FillBlanksStorage";
import {FirestoreFillBlanksService} from "./data/implementations/FirestoreFillBlanksService";
import {
    CreateFillBlanksInteractor,
    CreateFillBlanksInteractorImpl
} from "./domain/interfaces/CreateFillBlanksInteractor";
import {CreateFillBlanksServiceImpl} from "./data/implementations/CreateFillBlanksServiceImpl";
import {OpenAISpeechGenerator} from "./data/implementations/OpenAISpeechGenerator";
import {AudioUploader} from "./data/implementations/AudioUploader";

// Composition Root для конфигурации всех зависимостей
class CompositionRoot {
    private static wordParserInstance: WordParser;
    private static authServiceInstance: AuthService;
    private static userStorageInstance: UserStorage;
    static exercisesStorage: ExercisesStorage
    private static appUserManagerInstance: AppUserManager;
    private static wordsServiceInstance: WordsServiceProtocol;
    static exerciseCreateInteractor: ExerciseCreateInteractor;
    static saveExerciseInteractor: SaveExerciseInteractor
    static practiceSessionStorage: PracticeSessionStorage

    static fillBlanksStorage: FillBlanksStorage
    static createFillBlanksInteractor: CreateFillBlanksInteractor

    static initialize() {
        // Экземпляр OpenAIWordParser, реализующий WordParser
        this.wordParserInstance = new OpenAITextParser();

        // Создаём экземпляры authService и userStorage
        this.authServiceInstance = new FirebaseAuthService();
        this.userStorageInstance = new FirestoreUserService();

        // Создаём экземпляр AppUserManager, внедряя authService и userStorage
        this.appUserManagerInstance = new AppUserManager(this.authServiceInstance, this.userStorageInstance);


        this.wordsServiceInstance = FirestoreWordsService.getInstance();

        // this.exerciseCreateInteractor = new MockExerciseCreateInteractor();
        this.exerciseCreateInteractor = new RealExerciseCreateInteractor();
        this.exercisesStorage = new FirestoreExercisesService();
        console.log("initialize CompositionRoot, exercisesStorage: ", this.exercisesStorage)
        this.saveExerciseInteractor = new SaveExerciseInteractorImpl(this.exercisesStorage, this.appUserManagerInstance)
        this.practiceSessionStorage = new FirestorePracticeSessionService()
        this.fillBlanksStorage = new FirestoreFillBlanksService();
        this.createFillBlanksInteractor = new CreateFillBlanksInteractorImpl(
            new CreateFillBlanksServiceImpl(),
            new OpenAISpeechGenerator(),
            new AudioUploader(),
            this.fillBlanksStorage
        );
    }

    static getWordParser(): WordParser {
        return this.wordParserInstance;
    }

    static getExercisesStorage(): ExercisesStorage {
        return this.exercisesStorage;
    }

    static getAppUserManager(): AppUserManager {
        return this.appUserManagerInstance;
    }

    static getWordsService(): WordsServiceProtocol {
        return this.wordsServiceInstance;
    }
}

export default CompositionRoot;

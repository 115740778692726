// ./data/implementations/CreateFillBlanksServiceImpl.ts
import { CreateFillBlanksService } from "../../domain/interfaces/CreateFillBlanksService";
import { OpenAIRequester } from "./OpenAIRequester";
import {apiKey, geminiApiKey} from "../config/environment";
import {GeminiRequester} from "./GeminiRequester";

export class CreateFillBlanksServiceImpl implements CreateFillBlanksService {
    private openAIRequester: OpenAIRequester;
    private geminiRequester: GeminiRequester;

    public constructor() {
        this.openAIRequester = new OpenAIRequester(apiKey);
        this.geminiRequester = new GeminiRequester(geminiApiKey);
    }

    private fillBlanksPrompt(words: string): string {
        return `Create a dialogue in Spanish that contains these words: ${words}
Mark the words in the text with square brackets.
The words from this list should appear in random order in the text.
Divide sentences with new lines.
`;
    }

    async createText(words: string, selectedRequester: string): Promise<string> {
        const prompt = this.fillBlanksPrompt(words);

        // Define the structure of the expected response
        interface FillBlanksResponseDTO {
            text: string;
        }

        let response;

        if (selectedRequester === 'openai') {
            response = await this.openAIRequester.requestWithCustomFormat<FillBlanksResponseDTO>(
                prompt,
                { text: "" }
            );
        } else if (selectedRequester === 'gemini') {
            response = await this.geminiRequester.requestWithCustomFormat<FillBlanksResponseDTO>(
                prompt,
                { text: "" }
            );
        } else {
            throw new Error(`Unknown requester: ${selectedRequester}`);
        }

        return response[0]?.text ?? `Error: ${JSON.stringify(response)}`;
    }
}

// src/ui/components/settings/auth/AuthScreen.tsx
import React from 'react';
import SignedUserView from './SignedUserView';
import AuthView from './AuthView';

const AuthScreen: React.FC = () => {
    return (
        <div style={{ padding: '20px', textAlign: 'center' }}>
            <p>========================</p>
            <SignedUserView />
            <p>========================</p>
            <AuthView />
            <p>========================</p>
        </div>
    );
};

export default AuthScreen;

// src/components/SubscriptionScreen.tsx
import React from 'react';

const SubscriptionScreen: React.FC = () => {
    return (
        <div style={{ padding: '20px', textAlign: 'center' }}>
            <h2>Подписка</h2>
            <p>Здесь будут отображены детали подписки.</p>
        </div>
    );
};

export default SubscriptionScreen;


export interface ExerciseOptions {
    learnlang: string;
    theme: string;
    level: string;
    comment: string;
}

export interface ExerciseSentence {
    id: string;
    sentence: string;
    targetWord: string;
}

export enum ExerciseCreationType {
    MissingWord = 'missingWord',
    SelectOption = 'selectOption',
}
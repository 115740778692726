import React, { useState, useEffect } from 'react';
import { Exercise, ExerciseSelectOption } from "../../../../domain/models";

interface PracticeSelectOptionViewProps {
    id: string;
    exercise: ExerciseSelectOption;
    saveAttempt: (exercise: Exercise, answer: string) => void;
    goNext: () => void;
}

export const PracticeSelectOptionView: React.FC<PracticeSelectOptionViewProps> = ({
                                                                                      id,
                                                                                      exercise,
                                                                                      saveAttempt,
                                                                                      goNext,
                                                                                  }) => {
    const [isCorrect, setIsCorrect] = useState<boolean | null>(null);

    const options = [exercise.option1, exercise.option2, exercise.option3, exercise.option4];

    const optionPressed = (option: string) => {
        saveAttempt({ id: id, type: { kind: 'selectOption', data: exercise } }, option);
        checkAnswer(option);
    };

    const checkAnswer = (option: string) => {
        if (option === exercise.correctOption) {
            goNext();
        } else {
            setIsCorrect(false);
        }
    };

    // Добавляем useEffect для сброса состояния при изменении id
    useEffect(() => {
        setIsCorrect(null);
    }, [id]);

    return (
        <div>
            <h2>{exercise.sentence}</h2>
            {options.map((option, index) => (
                <button key={index} onClick={() => optionPressed(option)}>
                    {option}
                </button>
            ))}
            {isCorrect === false && (
                <div>
                    <p>Wrong</p>
                    <p>Correct answer: {exercise.correctOption}</p>
                    <button onClick={goNext}>Next</button>
                </div>
            )}
        </div>
    );
};

// ui/components/learning/modes/single_choice/SingleChoiceLearnView.tsx
import React from 'react';
import { LearningMode, WordItem } from '../../../../../domain/models';
import { SingleChoiceView } from './SingleChoiceView';
import { useSingleChoiceLearnViewModel } from './SingleChoiceLearnViewModel';

interface SingleChoiceLearnViewProps {
    wordsToLearn: WordItem[];
    allWords: WordItem[];
    markAsKnown: (word: WordItem, mode: LearningMode, success: boolean) => void;
    nextWord: () => void;
}

export const SingleChoiceLearnView: React.FC<SingleChoiceLearnViewProps> = ({
                                                                                wordsToLearn,
                                                                                allWords,
                                                                                markAsKnown,
                                                                                nextWord,
                                                                            }) => {
    const viewModel = useSingleChoiceLearnViewModel(wordsToLearn, allWords, markAsKnown, nextWord);

    return (
        <SingleChoiceView
            word={viewModel.word}
            correctTranslation={viewModel.correctTranslation}
            options={viewModel.options}
            selectedOption={viewModel.selectedOption}
            isCorrect={viewModel.isCorrect}
            onSelectOption={viewModel.handleSelectOption}
            onNextPress={viewModel.nextWord}
        />
    );
};

// ui/components/learning/modes/text_input/TextInputLearnView.tsx
import React, { useState, useEffect } from 'react';
import { TextInputView } from './TextInputView';
import { LearningMode, WordItem } from '../../../../../domain/models';

interface TextInputLearnViewProps {
    wordItem: WordItem;
    markAsKnown: (word: WordItem, mode: LearningMode, success: boolean) => void;
    nextWord: () => void;
}

export const TextInputLearnView: React.FC<TextInputLearnViewProps> = ({
                                                                          wordItem,
                                                                          markAsKnown,
                                                                          nextWord,
                                                                      }) => {
    const [inputText, setInputText] = useState('');
    const [hint, setHint] = useState<string | null>(null);
    const [showWordInForeignLanguage] = useState(Math.random() < 0.5);
    const [state, setState] = useState<'start' | 'showingHint'>('start');

    useEffect(() => {
        setHint(null);
        setInputText('');
    }, [wordItem]);

    const correctAnswer = showWordInForeignLanguage ? wordItem.translation : wordItem.word;

    const checkAnswer = (input: string) => {
        return input.trim().toLowerCase() === correctAnswer.trim().toLowerCase();
    };

    const createHint = () => {
        const halfLength = Math.ceil(correctAnswer.length / 2);
        return correctAnswer.slice(0, halfLength) + '*'.repeat(correctAnswer.length - halfLength);
    };

    const handleSubmit = () => {
        const isCorrect = checkAnswer(inputText);
        if (state === 'start') {
            if (isCorrect) {
                markAsKnown(wordItem, LearningMode.TextInput, true);
                nextWord();
            } else {
                setInputText('');
                setHint(createHint());
                setState('showingHint');
                markAsKnown(wordItem, LearningMode.TextInput, false);
            }
        } else if (state === 'showingHint') {
            if (isCorrect) {
                nextWord();
            } else {
                setHint(correctAnswer);
                setInputText('');
            }
        }
    };

    return (
        <TextInputView
            word={showWordInForeignLanguage ? wordItem.word : wordItem.translation}
            inputText={inputText}
            setInputText={setInputText}
            onSubmit={handleSubmit}
            hint={hint}
        />
    );
};

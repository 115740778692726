// src/data/implementations/FirestoreUserService.ts
import { UserStorage } from '../../domain/interfaces/UserStorage';
import {AppUser, fromDictionary, Student, Tutor} from '../../domain/models';
import { db } from '../../data/config/firebase';
import { doc, setDoc, onSnapshot, collection, getDocs, updateDoc, query, where, arrayUnion, Timestamp } from 'firebase/firestore';

class FirestoreUserService implements UserStorage {
    async saveUser(user: AppUser): Promise<void> {
        const userData = {
            uid: user.uid,
            email: user.email || "",
            status: user.status || "free",
            trialStartDate: user.trialStartDate ? Timestamp.fromDate(user.trialStartDate) : null,
            delayedSets: user.delayedSets || []
        };
        await setDoc(doc(db, "usersData", user.uid), userData, { merge: true });
    }

    observeUserData(userId: string, callback: (data: Record<string, any>) => void): () => void {
        const unsubscribe = onSnapshot(doc(db, "usersData", userId), (snapshot) => {
            callback(snapshot.data() || {});
        });
        return unsubscribe; // Вернем функцию для отписки
    }

    async addStudent(userId: string, student: Student): Promise<void> {
        const studentData = {
            email: student.email,
            addDate: student.addDate,
            isConfirmed: student.isConfirmed
        };
        await setDoc(doc(db, "usersData", userId, "students", student.id), studentData, { merge: true });
    }

    async getStudents(userId: string): Promise<Student[]> {
        const snapshot = await getDocs(collection(db, "usersData", userId, "students"));
        return snapshot.docs.map(doc => ({
            id: doc.id,
            email: doc.data().email,
            addDate: (doc.data().addDate as Timestamp).toDate(),
            isConfirmed: doc.data().isConfirmed
        }));
    }

    async getTutors(userId: string): Promise<Tutor[]> {
        const snapshot = await getDocs(collection(db, "usersData", userId, "tutors"));
        return snapshot.docs.map(doc => ({
            id: doc.id,
            email: doc.data().email,
            addDate: (doc.data().addDate as Timestamp).toDate(),
            isConfirmed: doc.data().isConfirmed
        }));
    }

    async addTutorForStudent(studentId: string): Promise<void> {
        // const currentUser = AppUserManager.getCurrentUser();
        // if (!currentUser) {
        //     throw new Error("No current user");
        // }
        // const tutorData = {
        //     email: currentUser.email || "",
        //     addDate: Timestamp.now(),
        //     isConfirmed: false
        // };
        // await setDoc(doc(db, "usersData", studentId, "tutors", currentUser.uid), tutorData);
    }

    async confirmTutor(tutorId: string, studentId: string): Promise<void> {
        // const studentRef = doc(db, "usersData", tutorId, "students", studentId);
        // const tutorRef = doc(db, "usersData", studentId, "tutors", tutorId);
        //
        // await db.runTransaction(async (transaction) => {
        //     transaction.update(studentRef, { isConfirmed: true });
        //     transaction.update(tutorRef, { isConfirmed: true });
        // });
    }

    async findUserByEmail(email: string): Promise<AppUser | null> {
        const userQuery = query(collection(db, "usersData"), where("email", "==", email));
        const querySnapshot = await getDocs(userQuery);
        const doc = querySnapshot.docs[0];

        if (!doc) return null;

        const data = doc.data();
        return fromDictionary({ ...data, uid: doc.id });
    }

    async sendSetToStudent(studentId: string, setId: string): Promise<void> {
        const userRef = doc(db, "usersData", studentId);
        await updateDoc(userRef, {
            delayedSets: arrayUnion(setId)
        });
    }

    async addExerciseSet(id: string, userId: string): Promise<void> {
        const userRef = doc(db, "usersData", userId);
        await updateDoc(userRef, {
            exerciseSets: arrayUnion(id) // Добавляем ID набора упражнений в массив
        });
    }
}

export default FirestoreUserService;

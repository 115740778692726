// src/components/EditAddingWordView.tsx
import React from 'react';
import IconTextFieldView from './IconTextFieldView';
import { WordItem } from '../../../domain/models/WordItem';

interface EditAddingWordViewProps {
    wordItem: WordItem;
    onDelete: () => void;
    onChange: (updatedWord: WordItem) => void;
}

const EditAddingWordView: React.FC<EditAddingWordViewProps> = ({ wordItem, onDelete, onChange }) => {
    const updateField = (field: keyof WordItem, value: string) => {
        onChange({ ...wordItem, [field]: value });
    };

    return (
        <div style={containerStyle}>
            <div>
                <IconTextFieldView
                    iconName="textformat"
                    placeholder="Word"
                    value={wordItem.word}
                    onChange={value => updateField('word', value)}
                />
                <IconTextFieldView
                    iconName="globe"
                    placeholder="Translation"
                    value={wordItem.translation}
                    onChange={value => updateField('translation', value)}
                />
                <IconTextFieldView
                    iconName="quote.bubble"
                    placeholder="Example"
                    value={wordItem.example || ''}
                    onChange={value => updateField('example', value || '')}
                />
            </div>
            <button onClick={onDelete} style={deleteButtonStyle}>🗑</button>
        </div>
    );
};

const containerStyle = {
    display: 'flex',
    alignItems: 'center',
    padding: '10px',
    backgroundColor: '#f9f9f9',
    borderRadius: '8px',
    marginBottom: '10px',
};

const deleteButtonStyle = {
    backgroundColor: 'transparent',
    border: 'none',
    color: 'red',
    cursor: 'pointer',
    fontSize: '20px',
};

export default EditAddingWordView;

import { useState } from 'react';
import { CreateExerciseState } from './CreateExerciseState';
import {
    ExerciseCreationType,
    ExerciseOptions,
    ExerciseSentence
} from '../../../../domain/models/ExerciseCreate';
import { Exercise } from '../../../../domain/models';
import CompositionRoot from '../../../../compositionRoot';
import {ExerciseCreateInteractor} from "../../../../domain/interfaces/ExerciseCreateInteractor";
import {useNavigate} from "react-router-dom";

interface CreateExerciseViewModel {
    state: CreateExerciseState;
    amount: string;
    setAmount: (value: string) => void;
    selectedType: ExerciseCreationType;
    setSelectedType: (type: ExerciseCreationType) => void;
    finalPrompt: string | null;
    exercises: Exercise[];
    setExercises: (exercises: Exercise[]) => void;
    exerciseOptions: ExerciseOptions;
    setExerciseOptions: (options: ExerciseOptions) => void;
    sentences: ExerciseSentence[];
    setSentences: (sentences: ExerciseSentence[]) => void;
    startPrompt: string;
    generate: () => Promise<void>;
    convert: () => Promise<void>;
    goNext: () => void;
}

function useCreateExerciseViewModel(): CreateExerciseViewModel {
    const navigate = useNavigate();

    const [state, setState] = useState<CreateExerciseState>(CreateExerciseState.Settings);
    const [amount, setAmount] = useState<string>('10');
    const [selectedType, setSelectedType] = useState<ExerciseCreationType>(
        ExerciseCreationType.MissingWord
    );
    const [finalPrompt, setFinalPrompt] = useState<string | null>(null);
    const [exercises, setExercises] = useState<Exercise[]>([]);
    const [exerciseOptions, setExerciseOptions] = useState<ExerciseOptions>({
        learnlang: 'Spanish',
        theme: 'subjunctive',
        level: 'B1',
        comment: '',
    });
    const [sentences, setSentences] = useState<ExerciseSentence[]>([]);

    const interactor: ExerciseCreateInteractor = CompositionRoot.exerciseCreateInteractor;

    const startPrompt = interactor.generateStartPrompt(exerciseOptions, parseInt(amount) || 0);

    const generate = async () => {
        setState(CreateExerciseState.GeneratingSentences);
        try {
            const generatedSentences = await interactor.generateSentences(
                exerciseOptions,
                parseInt(amount) || 0
            );

            const generatedFinalPrompt = interactor.generateFinalPrompt(
                selectedType,
                exerciseOptions,
                generatedSentences
            );

            setSentences(generatedSentences);
            setFinalPrompt(generatedFinalPrompt);
            setState(CreateExerciseState.SentencesGenerated);
        } catch (error) {
            console.error('Ошибка при генерации упражнений:', error);
            setState(CreateExerciseState.Settings);
        }
    };

    const convert = async () => {
        setState(CreateExerciseState.Converting);
        try {
            const generatedExercises = await interactor.generateExercises(
                selectedType,
                exerciseOptions,
                sentences
            );
            setExercises(generatedExercises);
            setState(CreateExerciseState.Editing);
        } catch (error) {
            console.error('Ошибка при конвертации упражнений:', error);
            setState(CreateExerciseState.SentencesGenerated);
        }
    };

    const goNext = () => { //TODO: rename to save
        navigate('/');
    };

    return {
        state,
        amount,
        setAmount,
        selectedType,
        setSelectedType,
        finalPrompt,
        exercises,
        setExercises,
        exerciseOptions,
        setExerciseOptions,
        sentences,
        setSentences,
        startPrompt,
        generate,
        convert,
        goNext,
    };
}

export default useCreateExerciseViewModel;

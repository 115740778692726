import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';

interface HStackProps {
    children: React.ReactNode[];
}

const HStack: React.FC<HStackProps> = ({ children }) => {
    const childrenArray = React.Children.toArray(children) as React.ReactElement[];

    return (
        <div style={{ display: 'flex', position: 'relative', width: '100%', height: '100%' }}>
            <AnimatePresence>
                {childrenArray.map((child, index) => {
                    const totalChildren = childrenArray.length;

                    // Рассчитываем ширину и позицию для текущего элемента
                    const widthPercentage = `${100 / totalChildren}%`;
                    const leftPercentage = `${(index / totalChildren) * 100}%`;

                    // Получаем ключ для элемента
                    const key = child.key || index;

                    return (
                        <motion.div
                            key={key}
                            initial={{
                                left: '100%',
                                opacity: 0,
                                width: widthPercentage,
                            }}
                            animate={{
                                left: leftPercentage,
                                opacity: 1,
                                width: widthPercentage,
                            }}
                            exit={{
                                left: '-100%',
                                opacity: 0,
                                width: widthPercentage,
                            }}
                            transition={{ duration: 0.4 }}
                            style={{
                                position: 'absolute',
                                top: 0,
                                height: '100%',
                            }}
                        >
                            {child}
                        </motion.div>
                    );
                })}
            </AnimatePresence>
        </div>
    );
};

export default HStack;

// src/domain/services/AppUserManager.ts
import { BehaviorSubject } from 'rxjs';
import { AppUser, fromDictionary } from '../models/AppUser';
import { AuthService } from '../interfaces/AuthService';
import { UserStorage } from '../interfaces/UserStorage';
import { AppUserManagerProtocol } from "../interfaces/AppUserManagerProtocol";

class AppUserManager implements AppUserManagerProtocol {
    private authService: AuthService;
    private userStorage: UserStorage;
    private _currentUserSubject: BehaviorSubject<AppUser | null>;

    constructor(authService: AuthService, userStorage: UserStorage) {
        this.authService = authService;
        this.userStorage = userStorage;
        this._currentUserSubject = new BehaviorSubject<AppUser | null>(null); // инициализация потока
        this.setupAuthStateListener();
    }

    get currentUser(): AppUser | null {
        return this._currentUserSubject.value; // возвращает текущее значение
    }

    currentUserPublisher() {
        return this._currentUserSubject.asObservable(); // поток для подписки
    }

    async updateUserStatus(status: string, trialStartDate: Date | null): Promise<void> {
        if (!this.currentUser) throw new Error('No current user');
        const updatedUser = { ...this.currentUser, status, trialStartDate };
        await this.userStorage.saveUser(updatedUser);
        this._currentUserSubject.next(updatedUser); // обновляем поток
    }

    private setupAuthStateListener() {
        this.authService.addStateDidChangeListener((userId, email) => {
            if (userId) {
                if (!this.currentUser || this.currentUser.uid !== userId) {
                    this.observeUserData(userId, email);
                }
            } else {
                this.signInAnonymously().catch(console.error);
            }
        });
    }

    private observeUserData(userId: string, email: string | null) {
        this.userStorage.observeUserData(userId, (data) => {
            if (Object.keys(data).length === 0) {
                this.createDefaultUserData(userId, email);
            } else {
                const appUser = fromDictionary({ ...data, uid: userId });
                if (appUser) {
                    this._currentUserSubject.next(appUser); // обновляем поток
                }
            }
        });
    }

    private createDefaultUserData(userId: string, email: string | null) {
        const defaultUser: AppUser = {
            uid: userId,
            email,
            status: 'free',
            trialStartDate: null,
            delayedSets: [],
            exerciseSets: []
        };
        this.userStorage.saveUser(defaultUser).catch(console.error);
        this._currentUserSubject.next(defaultUser); // обновляем поток
    }

    async signInAnonymously(): Promise<void> {
        await this.authService.signInAnonymously();
    }

    async registerUser(email: string, password: string): Promise<void> {
        await this.authService.registerUser(email, password);
    }

    async loginUser(email: string, password: string): Promise<void> {
        await this.authService.loginUser(email, password);
    }

    async signInWithGoogle(): Promise<void> {
        await this.authService.signInWithGoogle();
    }

    async signInWithApple(): Promise<void> {
        await this.authService.signInWithApple();
    }

    async signUpWithApple(): Promise<void> {
        await this.authService.signUpWithApple();
    }

    async signOut(): Promise<void> {
        await this.authService.signOut();
        this._currentUserSubject.next(null); // сбрасываем состояние
    }

    addExerciseSet(id: string, userId: string): Promise<void> {
        return this.userStorage.addExerciseSet(id, userId);
    }
}

export default AppUserManager;

// ./ui/components/statistics/LearnByDateView.tsx

import React, { useState, useMemo } from 'react';
import WordsViewModel from '../../viewmodels/WordsViewModel';
import { WordItem } from '../../../domain/models/WordItem';
import { WordItemModel } from '../../../domain/models/WordItemModel';
import { useNavigate } from 'react-router-dom';

interface LearnByDateViewProps {
    wordsViewModel: WordsViewModel;
}

const LearnByDateView: React.FC<LearnByDateViewProps> = ({ wordsViewModel }) => {
    const navigate = useNavigate();
    const [expandedSections, setExpandedSections] = useState<Set<string>>(new Set());

    const groupedWordsByDay = useMemo(() => {
        const grouped: { [key: string]: WordItem[] } = {};

        wordsViewModel.words.forEach(word => {
            const doneDate = WordItemModel.doneDate(word);
            if (doneDate) {
                const dateStr = new Date(doneDate).toDateString();
                if (!grouped[dateStr]) {
                    grouped[dateStr] = [];
                }
                grouped[dateStr].push(word);
            }
        });

        return grouped;
    }, [wordsViewModel.words]);

    const toggleSection = (dateStr: string) => {
        setExpandedSections(prev => {
            const newSet = new Set(prev);
            if (newSet.has(dateStr)) {
                newSet.delete(dateStr);
            } else {
                newSet.add(dateStr);
            }
            return newSet;
        });
    };

    const wordView = (word: WordItem) => {
        const handleClick = () => {
            navigate(`/wordDetails/${word.id}`);
        };

        return (
            <div key={word.id} style={wordItemStyle} onClick={handleClick}>
                <span style={{ fontWeight: 'bold', color: '#FFFFFF' }}>{word.word}</span>
                <span style={{ marginLeft: '10px', color: '#AAAAAA' }}>{word.translation}</span>
            </div>
        );
    };

    return (
        <div style={containerStyle}>
            {Object.keys(groupedWordsByDay)
                .sort((a, b) => new Date(b).getTime() - new Date(a).getTime())
                .map(dateStr => (
                    <div key={dateStr} style={sectionStyle}>
                        <div style={headerStyle} onClick={() => toggleSection(dateStr)}>
                            <span style={{ fontSize: '1.1em', fontWeight: 'bold', color: '#FFFFFF' }}>{dateStr}</span>
                            <span style={{ marginLeft: 'auto', color: '#00FF00' }}>
                                Learned: {groupedWordsByDay[dateStr].length}
                            </span>
                            <span style={{ marginLeft: '10px', cursor: 'pointer', color: '#66CCFF' }}>
                                {expandedSections.has(dateStr) ? '▼' : '▶'}
                            </span>
                        </div>
                        {expandedSections.has(dateStr) && (
                            <div style={wordsListStyle}>
                                {groupedWordsByDay[dateStr].map(word => wordView(word))}
                                <div style={{ fontSize: '0.8em', color: '#DDDDDD', marginTop: '5px' }}>
                                    Total: {groupedWordsByDay[dateStr].length} words
                                </div>
                            </div>
                        )}
                    </div>
                ))}
        </div>
    );
};

// Обновленный стиль контейнера
const containerStyle: React.CSSProperties = {
    padding: '20px',
    backgroundColor: '#2E2E2E', // Темный фон
    borderRadius: '10px',
};

// Обновленный стиль секции
const sectionStyle: React.CSSProperties = {
    marginBottom: '10px',
    backgroundColor: '#333333', // Темный фон секции
    borderRadius: '8px',
    boxShadow: '0 2px 5px rgba(0,0,0,0.3)',
};

// Обновленный стиль заголовка секции
const headerStyle: React.CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    padding: '10px',
    backgroundColor: '#444444', // Темный фон заголовка
    borderRadius: '8px',
    cursor: 'pointer',
};

// Стиль для списка слов в секции
const wordsListStyle: React.CSSProperties = {
    padding: '10px',
};

// Обновленный стиль элемента слова
const wordItemStyle: React.CSSProperties = {
    padding: '5px',
    borderBottom: '1px solid #666666',
    cursor: 'pointer',
    color: '#FFFFFF', // Цвет текста
};

export default LearnByDateView;


// ui/components/learning/LearnWordsContainerView.tsx
import React, { useState, useEffect } from 'react';
import {LearningState} from "../../../domain/models/LearningState";
import {LearnSessionManager} from "../../../domain/services/LearnSessionManager";
import {WordsServiceProtocol} from "../../../domain/interfaces/WordsServiceProtocol";
import {StandardLearnView} from "./modes/standard/StandardLearnView";
import {AddPictureView} from "./modes/add_picture/AddPictureView";
import {MultipleChoiceLearnView} from "./modes/multiple_choice/MultipleChoiceLearnView";
import {SingleChoiceLearnView} from "./modes/single_choice/SingleChoiceLearnView";
import {TextInputLearnView} from "./modes/text_input/TextInputLearnView";
import {SelectPictureLearnView} from "./modes/select_picture/SelectPictureLearnView";
import {ExamLearnView} from "./modes/exam/ExamLearnView";
import {AddingSimilarView} from "./modes/adding_similar/AddingSimilarView";
import {LearningMode, WordItem} from "../../../domain/models";

interface LearnWordsContainerViewProps {
    wordsService: WordsServiceProtocol;
    // Include other dependencies like notificationManager if needed
}

export const LearnWordsContainerView: React.FC<LearnWordsContainerViewProps> = ({ wordsService }) => {
    const [learningState, setLearningState] = useState<LearningState | null>(null);
    const learnSessionManager = new LearnSessionManager(wordsService);
    const [viewKey, setViewKey] = useState(0); // Состояние для уникального ключа

    useEffect(() => {
        // On component mount
        setupNextMode();

        // Optional: start tracking if needed
        // appTimer.startLearnTracking();

        // On component unmount
        return () => {
            // appTimer.stopLearnTracking();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const setupNextMode = () => {
        const state = learnSessionManager.getNextLearningState();
        setLearningState(state);
        setViewKey(prevKey => prevKey + 1); // Обновляем ключ при переходе к новому слову
    };

    const markAsKnown = (word: WordItem, mode: LearningMode, success: boolean) => {
        learnSessionManager.markAsKnown(word, mode, success);
    };

    if (!learningState) {
        return <div>Loading...</div>;
    }

    switch (learningState.type) {
        case 'standard':
            return (
                <StandardLearnView
                    key={viewKey}
                    wordItem={learningState.wordItem}
                    markAsKnown={markAsKnown}
                    nextWord={setupNextMode}
                />
            );
        case 'addPicture':
            return (
                <AddPictureView
                    key={viewKey}
                    wordItem={learningState.wordItem}
                    markAsKnown={markAsKnown}
                    nextWord={setupNextMode}
                />
            );
        case 'multipleChoice':
            return (
                <MultipleChoiceLearnView
                    key={viewKey}
                    wordItems={learningState.wordItems}
                    markAsKnown={markAsKnown}
                    nextWord={setupNextMode}
                />
            );
        case 'singleChoice':
            return (
                <SingleChoiceLearnView
                    key={viewKey}
                    wordsToLearn={learningState.wordsToLearn}
                    allWords={learningState.allWords}
                    markAsKnown={markAsKnown}
                    nextWord={setupNextMode}
                />
            );
        case 'textInput':
            return (
                <TextInputLearnView
                    key={viewKey}
                    wordItem={learningState.wordItem}
                    markAsKnown={markAsKnown}
                    nextWord={setupNextMode}
                />
            );
        case 'selectPicture':
            return (
                <SelectPictureLearnView
                    key={viewKey}
                    wordsToLearn={learningState.wordsToLearn}
                    allWords={learningState.allWords}
                    markAsKnown={markAsKnown}
                    nextWord={setupNextMode}
                />
            );
        case 'exam':
            return (
                <ExamLearnView
                    key={viewKey}
                    wordItem={learningState.wordItem}
                    markAsKnown={markAsKnown}
                    nextWord={setupNextMode}
                />
            );
        case 'addingSimilar':
            return (
                <AddingSimilarView
                    key={viewKey}
                    wordItem={learningState.wordItem}
                    markAsKnown={markAsKnown}
                    nextWord={setupNextMode}
                />
            );
        case 'done':
            return <NotEnoughWords />;
        default:
            return null;
    }
};

const NotEnoughWords: React.FC = () => {
    return <div>No words available for learning.</div>;
};

// CreateExerciseSettingsView.tsx
import React from 'react';
import {CreateExerciseState} from "./CreateExerciseState";
import {ExerciseOptions} from "../../../../domain/models/ExerciseCreate";

interface CreateExerciseSettingsViewProps {
    amount: string;
    setAmount: (value: string) => void;
    exerciseOptions: ExerciseOptions;
    setExerciseOptions: (options: ExerciseOptions) => void;
    state: CreateExerciseState;
    generate: () => void;
    startPrompt: string;
}

export const CreateExerciseSettingsView: React.FC<CreateExerciseSettingsViewProps> = ({
                                                                                          amount,
                                                                                          setAmount,
                                                                                          exerciseOptions,
                                                                                          setExerciseOptions,
                                                                                          state,
                                                                                          generate,
                                                                                          startPrompt,
                                                                                      }) => {
    const handleInputChange = (field: keyof ExerciseOptions, value: string) => {
        setExerciseOptions({ ...exerciseOptions, [field]: value });
    };

    return (
        <div style={{ maxWidth: '500px', padding: '16px' }}>
            <div>
                <label>Amount</label>
                <input type="text" value={amount} onChange={(e) => setAmount(e.target.value)} />
            </div>
            <div>
                <label>Language</label>
                <input
                    type="text"
                    value={exerciseOptions.learnlang}
                    onChange={(e) => handleInputChange('learnlang', e.target.value)}
                />
            </div>
            <div>
                <label>Level</label>
                <input
                    type="text"
                    placeholder="B1"
                    value={exerciseOptions.level}
                    onChange={(e) => handleInputChange('level', e.target.value)}
                />
            </div>
            <div>
                <label>Theme</label>
                <textarea
                    value={exerciseOptions.theme}
                    onChange={(e) => handleInputChange('theme', e.target.value)}
                    style={{ fontSize: '1.2em', height: '150px', width: '100%' }}
                />
            </div>
            <div>
                <label>Comment</label>
                <textarea
                    value={exerciseOptions.comment}
                    onChange={(e) => handleInputChange('comment', e.target.value)}
                    style={{ fontSize: '1.2em', height: '150px', width: '100%' }}
                />
            </div>
            {state === CreateExerciseState.GeneratingSentences ? (
                <div>Loading...</div>
            ) : (
                <button onClick={generate}>Generate</button>
            )}
            <div>
                <p>Prompt:</p>
                <p style={{ fontSize: '1.2em' }}>{startPrompt}</p>
            </div>
        </div>
    );
};

// ui/components/learning/modes/exam/ExamTextInputView.tsx
import React, { useEffect, useRef } from 'react';

interface ExamTextInputViewProps {
    word: string;
    inputText: string;
    setInputText: (text: string) => void;
    onSubmit: () => void;
    hint: string | null;
}

export const ExamTextInputView: React.FC<ExamTextInputViewProps> = ({
                                                                        word,
                                                                        inputText,
                                                                        setInputText,
                                                                        onSubmit,
                                                                        hint,
                                                                    }) => {
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        inputRef.current?.focus();
    }, []);

    return (
        <div>
            <h1>{word}</h1>
            <input
                ref={inputRef}
                type="text"
                value={inputText}
                onChange={(e) => setInputText(e.target.value)}
                placeholder={hint || 'Enter Translation'}
                style={{
                    padding: '10px',
                    fontSize: '20px',
                    borderRadius: '8px',
                    width: '300px',
                    margin: '10px 0',
                }}
                onKeyPress={(e) => {
                    if (e.key === 'Enter') onSubmit();
                }}
            />
            <button
                onClick={onSubmit}
                style={{
                    padding: '10px 20px',
                    backgroundColor: 'cyan',
                    color: 'black',
                    borderRadius: '8px',
                    fontSize: '16px',
                    cursor: 'pointer',
                    marginTop: '10px',
                }}
            >
                Check
            </button>
        </div>
    );
};

// src/data/implementations/FirebaseAuthService.ts
import { AuthService } from '../../domain/interfaces/AuthService';
import { getAuth, onAuthStateChanged, signInAnonymously, createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut, User, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import app from '../../data/config/firebase';

class FirebaseAuthService implements AuthService {
    private auth = getAuth(app);
    private googleProvider = new GoogleAuthProvider();

    addStateDidChangeListener(callback: (userId: string | null, email: string | null) => void): void {
        onAuthStateChanged(this.auth, (user) => {
            callback(user ? user.uid : null, user ? user.email : null);
        });
    }

    async signInAnonymously(): Promise<{ userId: string; email: string | null }> {
        const result = await signInAnonymously(this.auth);
        return { userId: result.user.uid, email: result.user.email };
    }

    async registerUser(email: string, password: string): Promise<{ userId: string; email: string | null }> {
        const result = await createUserWithEmailAndPassword(this.auth, email, password);
        return { userId: result.user.uid, email: result.user.email };
    }

    async loginUser(email: string, password: string): Promise<{ userId: string; email: string | null }> {
        const result = await signInWithEmailAndPassword(this.auth, email, password);
        return { userId: result.user.uid, email: result.user.email };
    }

    async signInWithGoogle(): Promise<{ userId: string; email: string | null }> {
        try {
            const result = await signInWithPopup(this.auth, this.googleProvider);
            return { userId: result.user.uid, email: result.user.email };
        } catch (error) {
            console.error("Error during Google Sign-In:", error);
            throw error;
        }
    }

    async signInWithApple(): Promise<{ userId: string; email: string | null }> {
        // Пустая реализация для входа через Apple
        return Promise.reject(new Error("Apple Sign-In not implemented"));
    }

    async signUpWithApple(): Promise<{ userId: string; email: string | null }> {
        // Пустая реализация для входа через Apple
        return Promise.reject(new Error("Apple Sign-Up not implemented"));
    }

    async signOut(): Promise<void> {
        await signOut(this.auth);
    }
}

export default FirebaseAuthService;

// ./ui/components/exercise/fill_blanks/CreateFillBlanksScreen.tsx
import React, {useEffect, useRef, useState} from 'react';
import CompositionRoot from "../../../../compositionRoot";
import {CreateFillBlanksInteractor} from "../../../../domain/interfaces/CreateFillBlanksInteractor";
import {useNavigate} from "react-router-dom";
import BaseButton from "../../BaseButton";
import "./CreateFillBlanksScreen.css";

const tempWords = `amasar
agujeros
violeta
lila
celeste
colgar`;

export const useCreateFillBlanksViewModel = () => {
    const [words, setWords] = useState<string>(tempWords);
    const [text, setText] = useState<string>("");
    const [name, setName] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const navigate = useNavigate();
    const [selectedRequester, setSelectedRequester] = useState('openai'); // Default to OpenAI

    const interactor: CreateFillBlanksInteractor = CompositionRoot.createFillBlanksInteractor

    const parseAndShowWords = async () => {
        if (!words.trim()) {
            return;
        }
        setIsLoading(true);
        try {
            const createdText = await interactor.createText(words, selectedRequester);
            setText(createdText);
        } catch (error) {
            console.error(error);
            alert("Error creating text");
        } finally {
            setIsLoading(false);
        }
    };

    const confirm = async () => {
        if (!name.trim()) {
            alert("Enter name");
            return;
        }
        setIsLoading(true);
        try {
            await interactor.confirm(name, text);
            navigate("/"); // Переходим на главную страницу после сохранения
        } catch (error) {
            console.error(error);
            alert("Error saving exercise");
        } finally {
            setIsLoading(false);
        }
    };

    return {
        words,
        setWords,
        text,
        setText,
        name,
        setName,
        isLoading,
        parseAndShowWords,
        confirm,
        selectedRequester,
        setSelectedRequester
    };
};

const CreateFillBlanksScreen: React.FC = () => {
    const vm = useCreateFillBlanksViewModel();
    const textareaRef = useRef<HTMLTextAreaElement>(null);

    const adjustHeight = () => {
        if (textareaRef.current) {
            textareaRef.current.style.height = "auto";
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
        }
    };

    useEffect(() => {
        adjustHeight();
    }, [vm.text]);

    return (
        <div className="container">
            {/* Блок 1: Ввод слов */}
            <div className="block">
                <label className="label">Paste words:</label>
                <textarea
                    className="textarea"
                    value={vm.words}
                    onChange={(e) => vm.setWords(e.target.value)}
                />

                <div className="requester-selection">
                    <label className="label">Select model:</label>
                    <select
                        value={vm.selectedRequester}
                        onChange={(e) => vm.setSelectedRequester(e.target.value)}
                    >
                        <option value="openai">OpenAI</option>
                        <option value="gemini">Gemini</option>
                    </select>
                </div>

                {vm.isLoading ? (
                    <p className="loading">Loading...</p>
                ) : (
                    <BaseButton color="#007bff" onClick={vm.parseAndShowWords}>
                        Generate dialogue
                    </BaseButton>
                )}
            </div>

            {/* Блок 2: Просмотр и редактирование текста */}
            {vm.text && (
                <div className="block">
                    <h3 className="label">Generated text:</h3>
                    <textarea
                        ref={textareaRef}
                        className="textarea2"
                        value={vm.text}
                        onChange={(e) => {
                            vm.setText(e.target.value);
                            adjustHeight();
                        }}
                    />
                </div>
            )}

            {/* Блок 3: Ввод названия и кнопка Confirm */}
            {vm.text && (
                <div className="block">
                    <div className="input-block">
                        <label className="label">Exercise name:</label>
                        <input
                            type="text"
                            className="input"
                            value={vm.name}
                            onChange={(e) => vm.setName(e.target.value)}
                        />
                    </div>
                    <div style={{ marginTop: "20px" }}>
                        {vm.isLoading ? (
                            <p className="loading">Loading...</p>
                        ) : (
                            <BaseButton
                                color={vm.name.trim() ? "#007bff" : "#ccc"}
                                onClick={vm.confirm}
                            >
                                Confirm
                            </BaseButton>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};


export default CreateFillBlanksScreen;

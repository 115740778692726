// ui/components/learning/modes/adding_similar/AddingSimilarView.tsx
import React from 'react';
import {LearningMode, WordItem} from "../../../../../domain/models";

interface AddingSimilarViewProps {
    wordItem: WordItem;
    markAsKnown: (word: WordItem, mode: LearningMode, success: boolean) => void;
    nextWord: () => void;
}

export const AddingSimilarView: React.FC<AddingSimilarViewProps> = ({
                                                                        wordItem,
                                                                        markAsKnown,
                                                                        nextWord,
                                                                    }) => {
    // Implement the adding similar logic
    return <div>Adding Similar Words Mode</div>;
};

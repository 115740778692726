// ./ui/components/exercise/fill_blanks/FillBlanksExercisesList.tsx
import React, { useState, useEffect } from 'react';
import CompositionRoot from "../../../../compositionRoot";
import {FillBlanksStorage} from "../../../../domain/interfaces/FillBlanksStorage";
import {Link} from "react-router-dom";
import {FillBlanksExerciseModel} from "../../../../domain/models/FillBlanksExerciseModel";
import BaseButton from "../../BaseButton";
import "./FillBlanksExercisesList.css";
import LinkButton from "../../LinkButton";

export const useFillBlanksExercisesListViewModel = () => {
    const [exercises, setExercises] = useState<FillBlanksExerciseModel[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<Error | null>(null);

    const storage: FillBlanksStorage = CompositionRoot.fillBlanksStorage

    const loadData = async () => {
        try {
            setIsLoading(true);
            const exercises = await storage.getFillBlanksExercises();
            setExercises(exercises);
        } catch (err) {
            console.error(err);
            setError(err as Error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        loadData();
    }, []);

    return { exercises, isLoading, error };
};


const FillBlanksExercisesList: React.FC = () => {
    const vm = useFillBlanksExercisesListViewModel();

    if (vm.isLoading) {
        return <div className="centered"><p>Loading...</p></div>;
    }

    if (vm.error) {
        return <div className="centered"><p>Error: {vm.error.message}</p></div>;
    }

    return (
        <div className="list-container">
            <h2 className="list-title">Exercises list</h2>
            <div className="button-list">
                {vm.exercises.map((exercise) => (
                    <LinkButton
                        href={`/fillBlanksExercise/${exercise.id}`}
                        key={exercise.id}
                        color="#007bff"
                        onClick={() => {
                            window.location.href = `/fillBlanksExercise/${exercise.id}`;
                        }}
                    >
                        {exercise.name}
                    </LinkButton>
                ))}
            </div>
        </div>
    );
};

export default FillBlanksExercisesList;

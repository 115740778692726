// ./data/implementations/AudioUploader.ts
import { AudioUploading } from "../../domain/interfaces/AudioUploading";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

export class AudioUploader implements AudioUploading {
    async uploadAudio(data: ArrayBuffer, fileName: string): Promise<string> {
        const storage = getStorage();
        const storageRef = ref(storage, `audio/${fileName}.mp3`);

        await uploadBytes(storageRef, data, {
            contentType: "audio/mpeg",
        });

        const downloadURL = await getDownloadURL(storageRef);
        return downloadURL;
    }
}

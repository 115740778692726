// src/components/start/StartScreen.tsx

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BaseButton from '../BaseButton';
import CompositionRoot from '../../../compositionRoot';
import { AppUser } from '../../../domain/models/AppUser';
import WordsViewModel from '../../viewmodels/WordsViewModel';
import ShortStatsView from '../statistics/ShortStatsView';

const StartScreen: React.FC = () => {
    const navigate = useNavigate();
    const [user, setUser] = useState<AppUser | null>(null);
    const [wordsViewModel, setWordsViewModel] = useState<WordsViewModel | null>(null);

    useEffect(() => {
        // Получаем информацию о текущем пользователе из AppUserManager
        const appUserManager = CompositionRoot.getAppUserManager();
        setUser(appUserManager.currentUser);

        // Получаем WordsServiceProtocol через CompositionRoot и создаем WordsViewModel
        const wordsService = CompositionRoot.getWordsService();
        const viewModel = new WordsViewModel(wordsService);
        setWordsViewModel(viewModel);

        // Подписываемся на изменения текущего пользователя
        const userPublisher = appUserManager.currentUserPublisher();
        const subscription = userPublisher.subscribe(setUser);

        // Отписываемся при размонтировании и освобождаем ресурсы ViewModel
        return () => {
            subscription.unsubscribe();
            viewModel.dispose();
        };
    }, []);

    return (
        <div style={{ position: 'relative', width: '100%', height: '100%' }}>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '10px', padding: '20px' }}>
                <BaseButton color="#00bcd4" onClick={() => navigate('/mainAdd')}>
                    Add words
                </BaseButton>
                <BaseButton color="#00bcd4" onClick={() => navigate('/mainStudy')}>
                    Study words
                </BaseButton>

                {wordsViewModel && <ShortStatsView wordsViewModel={wordsViewModel} />}
            </div>

            <div style={{ position: 'absolute', top: '16px', right: '16px', display: 'flex', flexDirection: 'column', gap: '10px' }}>
                <BaseButton color="#ff9800" onClick={() => navigate('/settings')}>
                    Settings
                </BaseButton>
                <BaseButton color="#ff9800" onClick={() => navigate('/mainStat')}>
                    Statistics
                </BaseButton>
                <BaseButton color="#9e9e9e" onClick={() => navigate('/delayedSets')}>
                    Delayed
                </BaseButton>
                <BaseButton color="#9e9e9e" onClick={() => navigate('/students')}>
                    Students
                </BaseButton>

                <BaseButton color="#00bcd4" onClick={() => navigate('/createExercise')}>
                    Create exercise
                </BaseButton>
                <BaseButton color="#00bcd4" onClick={() => navigate('/exercisesList')}>
                    Practice
                </BaseButton>
                <BaseButton color="#9e9e9e" onClick={() => navigate('/practiceResultsList')}>
                    Practice results
                </BaseButton>

                <BaseButton color="#00bcd4" onClick={() => navigate('/createFillBlanks')}>
                    Create fill blanks
                </BaseButton>

                <BaseButton color="#00bcd4" onClick={() => navigate('/fillBlanksList')}>
                    Fill blank exercises list
                </BaseButton>

            </div>
        </div>
    );
};

export default StartScreen;

// ui/components/learning/modes/select_picture/SelectPictureLearnView.tsx
import React from 'react';
import {LearningMode, WordItem} from "../../../../../domain/models";

interface SelectPictureLearnViewProps {
    wordsToLearn: WordItem[];
    allWords: WordItem[];
    markAsKnown: (word: WordItem, mode: LearningMode, success: boolean) => void;
    nextWord: () => void;
}

export const SelectPictureLearnView: React.FC<SelectPictureLearnViewProps> = ({
                                                                                  wordsToLearn,
                                                                                  allWords,
                                                                                  markAsKnown,
                                                                                  nextWord,
                                                                              }) => {
    // Implement the select picture logic
    return <div>Select Picture Learning Mode</div>;
};

// ./data/implementations/OpenAISpeechGenerator.ts
import { SpeechGenerating } from "../../domain/interfaces/SpeechGenerating";
import {apiKey} from "../config/environment";

export class OpenAISpeechGenerator implements SpeechGenerating {
    private apiKey: string;
    private model = "tts-1";

    constructor(api: string = apiKey) {
        this.apiKey = api;
    }

    async generateSpeech(inputText: string): Promise<ArrayBuffer> {
        const url = "https://api.openai.com/v1/audio/speech";

        const parameters = {
            model: this.model,
            voice: "alloy",
            input: inputText,
        };

        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Authorization": `Bearer ${this.apiKey}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(parameters),
        });

        if (!response.ok) {
            const errorDetails = await response.json();
            console.error("Error generating speech:", errorDetails);
            throw new Error(`Error generating speech: ${response.statusText}`);
        }

        return response.arrayBuffer();
    }
}

// src/ui/components/exercise/practice/ExercisePractice.tsx

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { PracticeSelectOptionView } from './PracticeSelectOptionView';
import { PracticeMissingWordView } from './PracticeMissingWordView';
import {Exercise, ExerciseMissingWord, ExerciseSelectOption, ExerciseSet} from "../../../../domain/models";
import {ExercisePracticeSession} from "../../../../domain/services/ExercisePracticeSession";
import CompositionRoot from "../../../../compositionRoot";

type PracticeStateUI =
    | { type: 'selectOption'; exercise: ExerciseSelectOption; id: string }
    | { type: 'missingWord'; exercise: ExerciseMissingWord; id: string }
    | { type: 'done' };

const ExercisePractice: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const [set, setSet] = useState<ExerciseSet | null>(null);
    const [practiceState, setPracticeState] = useState<PracticeStateUI>({ type: 'done' });
    const [session, setSession] = useState<ExercisePracticeSession | null>(null);

    const userManager = CompositionRoot.getAppUserManager();
    const storage = CompositionRoot.getExercisesStorage();
    const practiceStorage = CompositionRoot.practiceSessionStorage;

    useEffect(() => {
        const loadSet = async () => {
            try {
                if (id) {
                    console.log("loading getExerciseSet, id: ", id)
                    console.log("loading getExerciseSet, storage: ", storage)
                    const exerciseSet = await storage.getExerciseSet(id);
                    console.log("loaded exerciseSet: ", exerciseSet)
                    setSet(exerciseSet);
                    const practiceSession = new ExercisePracticeSession(exerciseSet, userManager, practiceStorage);
                    setSession(practiceSession);
                    getExercise(practiceSession);
                }
            } catch (error) {
                console.error(error);
            }
        };

        loadSet();
    }, [id]);

    const getExercise = (practiceSession: ExercisePracticeSession) => {
        const exercise = practiceSession.getExercise();
        if (!exercise) {
            setPracticeState({ type: 'done' });
            return;
        }
        switch (exercise.type.kind) {
            case 'selectOption':
                setPracticeState({ type: 'selectOption', exercise: exercise.type.data, id: exercise.id });
                break;
            case 'missingWord':
                setPracticeState({ type: 'missingWord', exercise: exercise.type.data, id: exercise.id  });
                break;
            default:
                setPracticeState({ type: 'done' });
                break;
        }
    };

    const saveAttempt = (exercise: Exercise, answer: string) => {
        session?.saveAttempt(exercise, answer);
    };

    const currentStepDone = () => {
        session?.goNext();
        if (session) {
            getExercise(session);
        }
    };

    return (
        <div>
            {practiceState.type === 'selectOption' && (
                <PracticeSelectOptionView
                    id={practiceState.id}
                    exercise={practiceState.exercise}
                    saveAttempt={saveAttempt}
                    goNext={currentStepDone}
                />
            )}
            {practiceState.type === 'missingWord' && (
                <PracticeMissingWordView
                    id={practiceState.id}
                    exercise={practiceState.exercise}
                    saveAttempt={saveAttempt}
                    goNext={currentStepDone}
                />
            )}
            {practiceState.type === 'done' && <p>Practice Done</p>}
        </div>
    );
};

export default ExercisePractice;

// domain/services/LearnSessionManager.ts
import {WordsServiceProtocol} from '../interfaces/WordsServiceProtocol';
import {WordItem} from '../models/WordItem';
import {LearningState} from '../models/LearningState';
import {LearningMode, WordState} from '../models/LearningMode';
import {WordItemModel} from "../models";

export class LearnSessionManager {
    private wordsService: WordsServiceProtocol;
    private addingSimilar: WordItem | null = null;

    constructor(wordsService: WordsServiceProtocol) {
        this.wordsService = wordsService;
    }

    public getNextLearningState(): LearningState {
        console.log("LearnSessionManager getNextLearningState start")
        if (this.addingSimilar) {
            const addingSimilar = this.addingSimilar;
            this.addingSimilar = null;
            return { type: 'addingSimilar', wordItem: addingSimilar };
        }

        // const learningModes: LearningMode[] = [LearningMode.MultipleChoice, LearningMode.SingleChoice, LearningMode.TextInput, LearningMode.SelectPicture]
        const learningModes: LearningMode[] = [LearningMode.SingleChoice]
        const additionalModes: LearningMode[] = [LearningMode.Standard, LearningMode.Exam]
        const modes: LearningMode[] = (learningModes.concat(additionalModes))
            .filter((mode) => mode !== LearningMode.Done)
            .filter((mode) => {
                const words = this.wordsService.getWordsFor(LearningMode.wordState(mode));
                if (mode === LearningMode.MultipleChoice) {
                    return words.length >= 4;
                }
                if (mode === LearningMode.SelectPicture) {
                    return words.some((word) => word.imageURL && word.imageURL !== 'skip');
                }
                return words.length > 0;
            });

        console.log("available modes: ", modes)

        if (modes.length === 0) {
            return { type: 'done' };
        }

        const mode = modes[Math.floor(Math.random() * modes.length)];
        console.log("random mode: ", mode)

        switch (mode) {
            case LearningMode.Standard:
                return this.setupStandard();
            case LearningMode.AddPicture:
                return this.setupAddPicture();
            case LearningMode.MultipleChoice:
                return this.setupMultipleChoice();
            case LearningMode.SingleChoice:
                return this.setupSingleChoice();
            case LearningMode.TextInput:
                return this.setupTextInput();
            case LearningMode.SelectPicture:
                return this.setupSelectPicture();
            case LearningMode.Exam:
                return this.setupExam();
            default:
                return { type: 'done' };
        }
    }

    public markAsKnown(word: WordItem, mode: LearningMode, success: boolean): void {
        this.wordsService.markAsKnown(word, mode, success);
        if (!success && WordItemModel.shouldAddSimilar(word)) {
            this.addingSimilar = word;
        }
    }

    private setupStandard(): LearningState {
        const words = this.wordsService.getWordsFor(WordState.Start);
        if (words.length > 0) {
            const wordItem = words[Math.floor(Math.random() * words.length)];
            return { type: 'standard', wordItem };
        }
        return { type: 'done' };
    }

    private setupAddPicture(): LearningState {
        const words = this.wordsService
            .getWordsFor(WordState.Start)
            .filter((word) => !word.imageURL);
        if (words.length > 0) {
            const wordItem = words[Math.floor(Math.random() * words.length)];
            return { type: 'addPicture', wordItem };
        }
        return { type: 'done' };
    }

    private setupMultipleChoice(): LearningState {
        const words = this.wordsService.getWordsFor(WordState.Learn).sort(() => Math.random() - 0.5);
        const wordItems = words.slice(0, 4);

        if (wordItems.length === 4) {
            return { type: 'multipleChoice', wordItems };
        }
        return { type: 'done' };
    }

    private setupSingleChoice(): LearningState {
        const wordsToLearn = this.wordsService.getWordsFor(WordState.Learn);
        const allWords = this.wordsService.getWordsFor(null);
        if (wordsToLearn.length > 0 && allWords.length > 0) {
            return { type: 'singleChoice', wordsToLearn, allWords };
        }
        return { type: 'done' };
    }

    private setupTextInput(): LearningState {
        const words = this.wordsService.getWordsFor(WordState.Learn);
        if (words.length > 0) {
            const wordItem = words[Math.floor(Math.random() * words.length)];
            return { type: 'textInput', wordItem };
        }
        return { type: 'done' };
    }

    private setupSelectPicture(): LearningState {
        const wordsToLearn = this.wordsService
            .getWordsFor(WordState.Learn)
            .filter((word) => word.imageURL && word.imageURL !== 'skip');
        const allWords = this.wordsService.getWordsFor(null);
        if (wordsToLearn.length > 0 && allWords.length > 0) {
            return { type: 'selectPicture', wordsToLearn, allWords };
        }
        return { type: 'done' };
    }

    private setupExam(): LearningState {
        const words = this.wordsService.getWordsFor(WordState.Exam);
        if (words.length > 0) {
            const wordItem = words[Math.floor(Math.random() * words.length)];
            return { type: 'exam', wordItem };
        }
        return { type: 'done' };
    }
}

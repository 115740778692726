// ./domain/models/LearningStateMachine.ts

import {LearningMode, WordState} from "./LearningMode";
import {Repetition} from "./Repetition";

class LearningStateMachine {
    mode: WordState = WordState.Start;
    successfulAttempts: number = 0;
    private repetitions: Repetition[] = [];

    constructor() {}

    private lastExamRepetitions(): Repetition[] {
        let index = this.repetitions.length - 1;
        let examAttempts: Repetition[] = [];
        let inExamMode = true;

        while (index >= 0 && inExamMode) {
            const repetition = this.repetitions[index];
            if (repetition.success && repetition.type === LearningMode.Exam) {
                examAttempts.unshift(repetition);
                index -= 1;
            } else {
                inExamMode = false;
            }
        }
        return examAttempts;
    }

    get timeToWait(): number {
        if (this.mode === WordState.Exam) {
            const lastDate = this.repetitions[this.repetitions.length - 1]?.date;
            if (!lastDate) return 0;

            const diff = Date.now() - new Date(lastDate).getTime();
            const examAttempts = this.lastExamRepetitions();

            let waitTime: number;
            switch (examAttempts.length) {
                case 0:
                    waitTime = 180 * 1000; // 3 минуты
                    break;
                case 1:
                    waitTime = 1800 * 1000; // 30 минут
                    break;
                case 2:
                    waitTime = 10800 * 1000; // 3 часа
                    break;
                case 3:
                    waitTime = 172800 * 1000; // 2 дня
                    break;
                default:
                    waitTime = 0;
            }

            return diff <= waitTime ? waitTime - diff : 0;
        } else {
            return 0;
        }
    }

    attempt(repetition: Repetition): void {
        this.repetitions.push(repetition);
        if (repetition.success) {
            this.successfulAttempts += 1;
            if (this.successfulAttempts >= WordState.attemptsToMove(this.mode)) {
                this.moveToNextMode();
            }
        } else {
            if (this.successfulAttempts === 0) {
                this.moveToPreviousMode();
            } else {
                if (this.mode === WordState.Exam) {
                    this.moveToPreviousMode();
                } else {
                    this.successfulAttempts = 0;
                }
            }
        }
    }

    private moveToNextMode(): void {
        this.successfulAttempts = 0;
        switch (this.mode) {
            case WordState.Start:
                this.mode = WordState.Learn;
                break;
            case WordState.Learn:
                this.mode = WordState.Exam;
                break;
            case WordState.Exam:
                this.mode = WordState.Done;
                break;
            case WordState.Done:
                break;
        }
    }

    private moveToPreviousMode(): void {
        switch (this.mode) {
            case WordState.Start:
                break;
            case WordState.Learn:
                this.mode = WordState.Start;
                break;
            case WordState.Exam:
                this.mode = WordState.Learn;
                break;
            case WordState.Done:
                this.mode = WordState.Exam;
                break;
        }
        this.successfulAttempts = 0;
    }
}

export default LearningStateMachine;

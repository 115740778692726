// src/data/promptTemplate.ts

export const promptForListOfWords = (text: string, baseLanguage: string = "Russian"): string => `
Here is a list of words, some with translations in ${baseLanguage} and some without:
${text}
For each word that has a translation, keep it as is.
For each word that does not have a translation, provide the translation in ${baseLanguage}.
For each word provide an example in a simple sentence in its original language, not ${baseLanguage}.
Remove unnecessary symbols like * or numbers from the words.
Do not delete any words from the list.
If the translation is not known, leave the word without translation.
Similar words with a separator should be split into two separate words.
`;

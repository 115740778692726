// src/data/implementations/OpenAITextParser.ts

import { WordParser } from '../../domain/interfaces/WordParser';
import { WordItem } from '../../domain/models/WordItem';
import { apiKey } from '../config/environment';
import { promptForListOfWords } from '../../domain/promptTemplate';
import { OpenAIRequester } from './OpenAIRequester';

export class OpenAITextParser implements WordParser {
    private requester: OpenAIRequester;

    public constructor(api: string = apiKey) {
        this.requester = new OpenAIRequester(api);
    }

    public async parse(text: string): Promise<WordItem[]> {
        const prompt = promptForListOfWords(text, "Russian");

        // Define an example WordTranslation object
        const exampleTranslation = {
            word: "example",
            translation: "пример",
            example: "Это пример предложения.",
        };

        const translations = await this.requester.requestWithCustomFormat(prompt, exampleTranslation);

        // Convert translations to WordItem[]
        const wordItems: WordItem[] = translations.map(translation => ({
            id: String(Date.now() + Math.random()), // Generate a unique ID
            word: translation.word,
            translation: translation.translation,
            example: translation.example,
            repetitions: [],
            imageURL: '',
        }));

        return wordItems;
    }
}


import React, { useRef, useEffect } from 'react';

interface MultipleChoiceViewProps {
    words: string[];
    options: string[];
    correctTranslations: { [key: string]: string };
    selectedWord: string | null;
    selectedOptions: { [key: string]: string };
    isCorrect: boolean | null;
    onSelectWord: (word: string) => void;
    onSelectOption: (option: string) => void;
    onNextButtonPress: () => void;
    onKeyPress: (key: number) => void;
}

export const MultipleChoiceView: React.FC<MultipleChoiceViewProps> = ({
                                                                          words,
                                                                          options,
                                                                          correctTranslations,
                                                                          selectedWord,
                                                                          selectedOptions,
                                                                          isCorrect,
                                                                          onSelectWord,
                                                                          onSelectOption,
                                                                          onNextButtonPress,
                                                                          onKeyPress,
                                                                      }) => {
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        // Устанавливаем фокус на контейнер при монтировании
        if (containerRef.current) {
            containerRef.current.focus();
        }
    }, []);

    const handleKeyPress = (event: React.KeyboardEvent) => {
        console.log("handleKeyPress inside MultipleChoiceView: ", event);
        const key = parseInt(event.key, 10);
        if (!isNaN(key)) {
            onKeyPress(key);
        }
    };

    const getWordBackgroundColor = (word: string) => {
        if (selectedOptions[word]) {
            return correctTranslations[word] === selectedOptions[word] ? 'green' : 'red';
        }
        return word === selectedWord ? 'gray' : 'blue';
    };

    const getOptionBackgroundColor = (option: string) => {
        const isOptionSelected = Object.values(selectedOptions).includes(option);
        return isOptionSelected
            ? Object.entries(selectedOptions).some(([word, selected]) => correctTranslations[word] === option)
                ? 'green'
                : 'red'
            : 'blue';
    };

    return (
        <div
            ref={containerRef}
            onKeyDown={handleKeyPress}
            tabIndex={0}
            style={{ outline: 'none', textAlign: 'center', padding: '20px' }}
        >
            <h2>Выберите правильные переводы</h2>
            <div style={{ display: 'flex', justifyContent: 'center', gap: '20px', marginTop: '20px' }}>
                {/* Колонка с кнопками слов */}
                <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                    {words.map((word, index) => (
                        <button
                            key={index}
                            onClick={() => onSelectWord(word)}
                            disabled={Boolean(selectedOptions[word] || isCorrect !== null)}
                            style={{
                                padding: '10px',
                                width: '200px',
                                backgroundColor: getWordBackgroundColor(word),
                                color: 'white',
                                fontSize: '16px',
                                borderRadius: '8px',
                            }}
                        >
                            {word}
                        </button>
                    ))}
                </div>

                {/* Колонка с кнопками вариантов */}
                <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                    {options.map((option, index) => (
                        <button
                            key={index}
                            onClick={() => onSelectOption(option)}
                            disabled={Boolean(Object.values(selectedOptions).includes(option) || isCorrect !== null)}
                            style={{
                                padding: '10px',
                                width: '200px',
                                backgroundColor: getOptionBackgroundColor(option),
                                color: 'white',
                                fontSize: '16px',
                                borderRadius: '8px',
                            }}
                        >
                            {option}
                        </button>
                    ))}
                </div>
            </div>
            {isCorrect === false && (
                <button
                    onClick={onNextButtonPress}
                    style={{
                        marginTop: '20px',
                        padding: '10px 20px',
                        backgroundColor: 'blue',
                        color: 'white',
                        borderRadius: '8px',
                        fontSize: '16px',
                        cursor: 'pointer',
                    }}
                >
                    Next
                </button>
            )}
        </div>
    );
};

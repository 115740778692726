import React, { useState, useEffect, useRef } from 'react';
import { Exercise, ExerciseMissingWord } from "../../../../domain/models";

interface PracticeMissingWordViewProps {
    id: string;
    exercise: ExerciseMissingWord;
    saveAttempt: (exercise: Exercise, answer: string) => void;
    goNext: () => void;
}

export const PracticeMissingWordView: React.FC<PracticeMissingWordViewProps> = ({
                                                                                    id,
                                                                                    exercise,
                                                                                    saveAttempt,
                                                                                    goNext,
                                                                                }) => {
    const [inputText, setInputText] = useState('');
    const [isCorrect, setIsCorrect] = useState<boolean | null>(null);
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        inputRef.current?.focus();
    }, []);

    // Добавляем useEffect для сброса состояния при изменении id
    useEffect(() => {
        setInputText('');
        setIsCorrect(null);
    }, [id]);

    const checkPressed = () => {
        const answer = inputText;
        saveAttempt({ id: id, type: { kind: 'missingWord', data: exercise } }, answer);
        checkAnswer(answer);
    };

    const checkAnswer = (answer: string) => {
        if (answer === exercise.correctForm) {
            setIsCorrect(true);
            goNext();
        } else {
            setIsCorrect(false);
        }
    };

    const enterPressed = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter') {
            if (isCorrect === null) {
                checkPressed();
            } else {
                goNext();
            }
        }
    };

    return (
        <div>
            <h2>
                {exercise.sentence}
            </h2>
            <input
                type="text"
                value={inputText}
                onChange={(e) => setInputText(e.target.value)}
                onKeyDown={enterPressed}
                ref={inputRef}
                placeholder="Enter word"
                style={{ fontSize: '20px', padding: '8px', width: '400px' }}
            />
            {isCorrect === null && <button onClick={checkPressed}>Check</button>}
            {isCorrect === false && (
                <div>
                    <p>Wrong</p>
                    <p>Correct answer: {exercise.correctForm}</p>
                    <button onClick={goNext}>Next</button>
                </div>
            )}
        </div>
    );
};

import { PracticeSessionStorage } from '../../domain/interfaces/PracticeSessionStorage';
import { PracticeSessionResults } from '../../domain/models/PracticeSessionResults';
import { db } from '../config/firebase';
import { doc, setDoc, getDocs, collection, Timestamp, QuerySnapshot } from 'firebase/firestore';

export class FirestorePracticeSessionService implements PracticeSessionStorage {
    constructor() {}

    /**
     * Сохраняет результаты практики для пользователя в Firestore.
     * @param results Результаты практики для сохранения.
     * @param userId ID пользователя.
     */
    public async saveResults(results: PracticeSessionResults, userId: string): Promise<void> {
        // Подготовка данных для сохранения результатов
        const resultsData = {
            id: results.id,
            setId: results.setId,
            startTime: Timestamp.fromDate(results.startTime),
            answers: results.answers,
        };

        // Сохранение данных в коллекцию "practiceResults" пользователя
        const practiceResultsRef = doc(db, 'usersData', userId, 'practiceResults', results.id);
        await setDoc(practiceResultsRef, resultsData);
    }

    /**
     * Получает все результаты практики для пользователя из Firestore.
     * @param userId ID пользователя.
     * @returns Массив результатов практики.
     */
    public async fetchAllResults(userId: string): Promise<PracticeSessionResults[]> {
        const allResults: PracticeSessionResults[] = [];

        // Запрос к коллекции practiceResults пользователя
        const practiceResultsCollectionRef = collection(db, 'usersData', userId, 'practiceResults');
        const querySnapshot: QuerySnapshot = await getDocs(practiceResultsCollectionRef);

        // Обработка документов
        querySnapshot.forEach((docSnap) => {
            const data = docSnap.data();

            // Проверка наличия необходимых полей
            const id = data.id as string;
            const setId = data.setId as string;
            const startTimeRaw = data.startTime;
            const answers = data.answers as { [exerciseId: string]: string };

            if (
                typeof id !== 'string' ||
                typeof setId !== 'string' ||
                !(startTimeRaw instanceof Timestamp) ||
                typeof answers !== 'object'
            ) {
                // Пропустить документы с неверным форматом
                return;
            }

            const startTime = startTimeRaw.toDate();

            const result: PracticeSessionResults = {
                id: id,
                setId: setId,
                startTime: startTime,
                answers: answers,
            };

            allResults.push(result);
        });

        return allResults;
    }
}

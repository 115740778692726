import { GoogleGenerativeAI, SchemaType } from '@google/generative-ai';

export class GeminiRequester {
    private apiKey: string;
    private modelName: string = 'gemini-1.5-pro'; // or 'gemini-1.5-flash'

    public constructor(apiKey: string) {
        this.apiKey = apiKey;
    }

    private generateJsonSchema<T>(example: T): any {
        const properties: { [key: string]: any } = {};
        const required: string[] = [];

        for (const key in example) {
            if (Object.prototype.hasOwnProperty.call(example, key)) {
                const value = example[key];
                let type = SchemaType.STRING; // Default to string

                if (typeof value === 'number') {
                    type = SchemaType.NUMBER;
                } else if (typeof value === 'boolean') {
                    type = SchemaType.BOOLEAN;
                } else if (Array.isArray(value)) {
                    type = SchemaType.ARRAY;
                } else if (typeof value === 'object' && value !== null) {
                    type = SchemaType.OBJECT;
                }

                properties[key] = {
                    type,
                    description: '', // Optional description
                    nullable: false,
                };
                required.push(key);
            }
        }

        // Assuming the response should have a "translations" array
        return {
            description: 'Response schema',
            type: SchemaType.OBJECT,
            properties: {
                translations: {
                    type: SchemaType.ARRAY,
                    items: {
                        type: SchemaType.OBJECT,
                        properties,
                        required,
                    },
                },
            },
            required: ['translations'],
        };
    }

    public async requestWithCustomFormat<T>(prompt: string, example: T): Promise<T[]> {
        const genAI = new GoogleGenerativeAI(this.apiKey);

        const schema = this.generateJsonSchema(example);

        const model = genAI.getGenerativeModel({
            model: this.modelName,
            generationConfig: {
                responseMimeType: 'application/json',
                responseSchema: schema,
                maxOutputTokens: 1500,
                temperature: 0.2,
            },
        });

        const result = await model.generateContent(prompt);

        const content = result.response.text();

        console.log('Received JSON:', content);

        let responseJson;
        try {
            responseJson = JSON.parse(content);
        } catch (error) {
            throw new Error('Failed to parse assistant response as JSON');
        }

        const translations: T[] = responseJson.translations;
        if (!translations) {
            throw new Error('No translations in response');
        }

        return translations;
    }
}

import React, { useCallback, useRef, useEffect } from 'react';

interface BaseLearnViewProps {
    word: string;
    translation: string;
    onMarkAsKnown: (known: boolean) => void;
    onNextWord: () => void;
}

export const BaseLearnView: React.FC<BaseLearnViewProps> = ({
                                                                word,
                                                                translation,
                                                                onMarkAsKnown,
                                                                onNextWord
                                                            }) => {
    // Создаем ref для корневого элемента
    const containerRef = useRef<HTMLDivElement>(null);

    // Устанавливаем фокус на корневой div при монтировании
    useEffect(() => {
        if (containerRef.current) {
            containerRef.current.focus();
        }
    }, []);

    // Обработчик для клавиши Enter
    const handleKeyPress = useCallback((event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter') {
            onMarkAsKnown(true);
            onNextWord();
        }
    }, [onMarkAsKnown, onNextWord]);

    return (
        <div
            ref={containerRef}
            onKeyDown={handleKeyPress}
            tabIndex={0}
            style={{ outline: 'none' }}
        >
            <h1 style={{ fontSize: '2rem' }}>{word}</h1>
            <h2 style={{ fontSize: '2rem' }}>{translation}</h2>
            <div style={{ display: 'flex', gap: '10px', marginTop: '20px' }}>
                <button
                    onClick={() => {
                        onMarkAsKnown(false);
                        onNextWord();
                    }}
                    style={buttonStyles(false)}
                >
                    Не знаю
                </button>
                <button
                    onClick={() => {
                        onMarkAsKnown(true);
                        onNextWord();
                    }}
                    style={buttonStyles(true)}
                >
                    Знаю
                </button>
            </div>
        </div>
    );
};

// Стили для кнопок в зависимости от состояния
const buttonStyles = (isKnown: boolean): React.CSSProperties => ({
    padding: '10px 20px',
    borderRadius: '8px',
    width: '150px',
    backgroundColor: isKnown ? 'cyan' : 'blue',
    color: isKnown ? 'black' : 'white',
    fontWeight: 'bold',
    fontSize: '1rem',
    cursor: 'pointer',
    border: 'none',
    outline: 'none',
});

import React from 'react';
import { CreateExerciseSettingsView } from './CreateExerciseSettingsView';
import { SentencesCheckView } from './SentencesCheckView';
import { CreateExerciseState } from './CreateExerciseState';
import EditExerciseView from './EditExerciseView';
import useCreateExerciseViewModel from './CreateExerciseViewModel';
import HStack from "./HStack";

interface CreateExerciseViewProps {}

const CreateExerciseView: React.FC<CreateExerciseViewProps> = () => {
    const vm = useCreateExerciseViewModel();

    return (
        <div style={{ width: '100%', height: '100%', position: 'relative' }}>
            <HStack>
                {(vm.state === CreateExerciseState.Settings ||
                    vm.state === CreateExerciseState.GeneratingSentences ||
                    vm.state === CreateExerciseState.SentencesGenerated) && (
                    <div style={{
                        padding: '16px',
                        flex: 1,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                    >
                        <CreateExerciseSettingsView
                            amount={vm.amount}
                            setAmount={vm.setAmount}
                            exerciseOptions={vm.exerciseOptions}
                            setExerciseOptions={vm.setExerciseOptions}
                            state={vm.state}
                            generate={vm.generate}
                            startPrompt={vm.startPrompt}
                        />
                    </div>
                )}
                {(vm.state === CreateExerciseState.GeneratingSentences ||
                    vm.state === CreateExerciseState.SentencesGenerated ||
                    vm.state === CreateExerciseState.Converting ||
                    vm.state === CreateExerciseState.Editing) && (
                    <div
                        style={{
                            flex: 1,
                            maxWidth: vm.state === CreateExerciseState.Editing ? '500px' : '100%',
                            padding: '16px',
                        }}
                    >
                        <SentencesCheckView
                            sentences={vm.sentences}
                            setSentences={vm.setSentences}
                            selectedType={vm.selectedType}
                            setSelectedType={vm.setSelectedType}
                            state={vm.state}
                            convert={vm.convert}
                        />
                    </div>
                )}
                {vm.state === CreateExerciseState.Editing && (
                    <div style={{ padding: '16px', flex: 1 }}>
                        <EditExerciseView
                            exercises={vm.exercises}
                            setExercises={vm.setExercises}
                            onSave={vm.goNext}
                        />
                    </div>
                )}
            </HStack>
        </div>
    );
};

export default CreateExerciseView;

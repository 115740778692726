// ./ui/components/statistics/ShortStatsView.tsx
import React, { useEffect, useState } from 'react';
import WordsViewModel from '../../viewmodels/WordsViewModel';

interface Props {
    wordsViewModel: WordsViewModel;
}

const ShortStatsView: React.FC<Props> = ({ wordsViewModel }) => {
    const [totalWords, setTotalWords] = useState(0);
    const [learnedWords, setLearnedWords] = useState(0);
    const [learningWords, setLearningWords] = useState(0);

    useEffect(() => {
        setTotalWords(wordsViewModel.totalWords);
        setLearnedWords(wordsViewModel.learnedWords);
        setLearningWords(wordsViewModel.learningWords);

        const subscription = wordsViewModel.subscribeToWordsUpdates().subscribe(() => {
            setTotalWords(wordsViewModel.totalWords);
            setLearnedWords(wordsViewModel.learnedWords);
            setLearningWords(wordsViewModel.learningWords);
        });

        return () => {
            subscription.unsubscribe();
        };
    }, [wordsViewModel]);

    return (
        <div style={{ textAlign: 'right', padding: '8px' }}>
            <p style={{ fontSize: '1.25rem', fontWeight: 'bold' }}>Total words: {totalWords}</p>
            <p style={{ fontSize: '1.25rem', fontWeight: 'bold' }}>Learned: {learnedWords}</p>
            <p style={{ fontSize: '1.25rem', fontWeight: 'bold' }}>Learning: {learningWords}</p>
        </div>
    );
};

export default ShortStatsView;

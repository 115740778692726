// src/data/config/environment.ts
export const apiKey = process.env.REACT_APP_OPENAI_API_KEY || '';

export const geminiApiKey = process.env.REACT_APP_GEMINI_API_KEY || '';

export const firebaseConfig = {
    apiKey: "AIzaSyBsbHYBXGCfWOi9n6plVNlKvhAIPZwQDbQ",
    authDomain: "dictum2-9d42b.firebaseapp.com",
    projectId: "dictum2-9d42b",
    storageBucket: "dictum2-9d42b.appspot.com",
    messagingSenderId: "938699596870",
    appId: "1:938699596870:web:01d510339cea709a11d647"
};
// ui/components/learning/modes/exam/ExamLearnViewModel.tsx

import { useState, useEffect, useCallback } from 'react';
import { LearningMode, WordItem } from '../../../../../domain/models';

interface ExamLearnViewModel {
    inputText: string;
    hint: string | null;
    showLearnedMessage: boolean;
    word: string;
    correctAnswer: string;
    setInputText: (text: string) => void;
    submitAnswer: () => void;
}

export function useExamLearnViewModel(
    wordItem: WordItem,
    markAsKnown: (word: WordItem, mode: LearningMode, success: boolean) => void,
    nextWord: () => void
): ExamLearnViewModel {
    const [inputText, setInputText] = useState('');
    const [hint, setHint] = useState<string | null>(null);
    const [showLearnedMessage, setShowLearnedMessage] = useState(false);
    const [state, setState] = useState<'start' | 'showingHint'>('start');

    const showWordInForeignLanguage = false;
    const word = showWordInForeignLanguage ? wordItem.word : wordItem.translation;
    const correctAnswer = showWordInForeignLanguage ? wordItem.translation : wordItem.word;

    useEffect(() => {
        setHint(null);
        setInputText('');
        setShowLearnedMessage(false);
    }, [wordItem]);

    const checkAnswer = () => {
        return (
            inputText.trim().toLowerCase() === correctAnswer.trim().toLowerCase()
        );
    };

    const createHint = (answer: string) => {
        const halfLength = Math.ceil(answer.length / 2);
        return (
            answer.slice(0, halfLength) +
            '*'.repeat(answer.length - halfLength)
        );
    };

    const submitAnswer = useCallback(() => {
        if (state === 'start') {
            const isCorrect = checkAnswer();
            markAsKnown(wordItem, LearningMode.Exam, isCorrect);
            if (isCorrect) {
                nextWord()
            } else {
                setInputText('');
                setHint(createHint(correctAnswer));
                setState('showingHint');
            }
        } else if (state === 'showingHint') {
            const isCorrect = checkAnswer();
            if (isCorrect) {
                nextWord()
            } else {
                setInputText('');
                setHint(correctAnswer);
            }
        }
    }, [inputText, state, correctAnswer, markAsKnown, wordItem]);

    const showLearnedAnimation = () => {
        setShowLearnedMessage(true);
        setTimeout(() => {
            setShowLearnedMessage(false);
            nextWord();
        }, 2000);
    };

    return {
        inputText,
        hint,
        showLearnedMessage,
        word,
        correctAnswer,
        setInputText,
        submitAnswer,
    };
}

// src/ui/components/settings/SettingsScreen.tsx
import React, { useState } from 'react';
import BaseButton from '../BaseButton';
import { useNavigate } from "react-router-dom";

const SettingsScreen: React.FC = () => {
    const navigate = useNavigate();

    const languages = ["English", "Russian", "Spanish", "French", "German", "Chinese", "Japanese", "Italian", "Portuguese"];
    const [appLanguage, setAppLanguage] = useState("English");
    const [baseLanguage, setBaseLanguage] = useState("Russian");
    const [learningLanguage, setLearningLanguage] = useState("Spanish");
    const [isLoading, setIsLoading] = useState(false);

    const handleLanguageChange = async (setter: React.Dispatch<React.SetStateAction<string>>, language: string) => {
        setIsLoading(true);
        setter(language);
        setIsLoading(false);
    };

    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            textAlign: 'center',
        }}>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center', // Центрирование содержимого по горизонтали
                width: '300px', // Ограничение ширины внутреннего контейнера для выравнивания
            }}>
                <h2>Settings</h2>
                <div style={{ marginBottom: '20px', width: '100%' }}>
                    <label>App language</label>
                    <select style={{ width: '100%' }} value={appLanguage} onChange={(e) => handleLanguageChange(setAppLanguage, e.target.value)}>
                        {languages.map(lang => <option key={lang} value={lang}>{lang}</option>)}
                    </select>
                </div>
                <div style={{ marginBottom: '20px', width: '100%' }}>
                    <label>Base language</label>
                    <select style={{ width: '100%' }} value={baseLanguage} onChange={(e) => handleLanguageChange(setBaseLanguage, e.target.value)}>
                        {languages.map(lang => <option key={lang} value={lang}>{lang}</option>)}
                    </select>
                </div>
                <div style={{ marginBottom: '20px', width: '100%' }}>
                    <label>Learning language</label>
                    <select style={{ width: '100%' }} value={learningLanguage} onChange={(e) => handleLanguageChange(setLearningLanguage, e.target.value)}>
                        {languages.map(lang => <option key={lang} value={lang}>{lang}</option>)}
                    </select>
                </div>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '10px',
                    width: '100%', // Выравнивание кнопок по всей ширине контейнера
                    alignItems: 'center', // Центрирование кнопок
                }}>
                    <BaseButton color="#00bcd4" onClick={() => {/* navigate to words details screen */}}>Word details</BaseButton>
                    <BaseButton color="#00bcd4" onClick={() => { navigate('/auth') }}>Auth</BaseButton>
                    <BaseButton color="#00bcd4" onClick={() => {/* navigate to notifications screen */}}>Notifications</BaseButton>
                    <BaseButton color="#00bcd4" onClick={() => {/* navigate to learn types screen */}}>Repetition methods</BaseButton>
                    <BaseButton color="#9e9e9e" onClick={() => {/* navigate to debug menu screen */}}>Debug menu</BaseButton>
                </div>
                {isLoading && <p>Loading...</p>}
            </div>
        </div>
    );
};

export default SettingsScreen;

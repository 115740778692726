// src/components/IconTextFieldView.tsx
import React from 'react';

interface IconTextFieldViewProps {
    iconName: string;
    placeholder: string;
    value: string;
    onChange: (value: string) => void;
}

const IconTextFieldView: React.FC<IconTextFieldViewProps> = ({ iconName, placeholder, value, onChange }) => {
    return (
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
            <span style={{ fontSize: '20px', marginRight: '8px' }}>{iconName}</span>
            <input
                type="text"
                placeholder={placeholder}
                value={value}
                onChange={e => onChange(e.target.value)}
                style={inputStyle}
            />
        </div>
    );
};

const inputStyle = {
    padding: '8px',
    fontSize: '16px',
    borderRadius: '8px',
    border: '1px solid #ccc',
    flex: 1,
};

export default IconTextFieldView;

// src/ui/components/settings/auth/AuthView.tsx
import React, { useState } from 'react';
import { useAuth } from '../../../context/AuthContext';

const AuthView: React.FC = () => {
    const { login, signInWithGoogle, signInWithApple, register } = useAuth();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const handleLogin = async () => {
        try {
            await login(email, password);
        } catch (error) {
            setErrorMessage("Login failed");
            console.error("Login error:", error);
        }
    };

    const handleRegister = async () => {
        try {
            await register(email, password);
        } catch (error) {
            setErrorMessage("Registration failed");
            console.error("Registration error:", error);
        }
    };

    return (
        <div style={{ padding: '10px' }}>
            <div style={{ marginBottom: '20px' }}>
                <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Email"
                />
                <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Password"
                />
            </div>
            <button onClick={handleRegister}>Register</button>
            <button onClick={handleLogin}>Login</button>
            <button onClick={signInWithGoogle}>Sign in with Google</button>
            <button onClick={signInWithApple}>Sign in with Apple</button>
            {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
        </div>
    );
};

export default AuthView;

// src/App.tsx
import React from 'react';
import StartScreenMacOS from './components/start/StartScreenMacOS';
import {AuthProvider} from "./context/AuthContext";

const App: React.FC = () => {
    return (
        <AuthProvider>
            <div className="App">
                <StartScreenMacOS />
            </div>
        </AuthProvider>
    );
};

export default App;

// ui/components/learning/modes/single_choice/SingleChoiceLearnViewModel.tsx
import { useState, useEffect, useCallback } from 'react';
import { LearningMode, WordItem } from '../../../../../domain/models';

interface SingleChoiceLearnViewModel {
    word: string;
    correctTranslation: string;
    options: string[];
    selectedOption: string | null;
    isCorrect: boolean | null;
    handleSelectOption: (option: string) => void;
    handleKeyPress: (event: KeyboardEvent) => void;
    nextWord: () => void;
}

export function useSingleChoiceLearnViewModel(
    wordsToLearn: WordItem[],
    allWords: WordItem[],
    markAsKnown: (word: WordItem, mode: LearningMode, success: boolean) => void,
    nextWord: () => void
): SingleChoiceLearnViewModel {
    // Use a single randomly selected `wordItem` like in Swift.
    const [wordItem] = useState<WordItem>(() => {
        const randomWord = wordsToLearn[Math.floor(Math.random() * wordsToLearn.length)];
        console.log("Случайно выбранное слово:", randomWord);
        return randomWord;
    });    const [showWordInForeignLanguage] = useState<boolean>(Math.random() < 0.5);
    const [options, setOptions] = useState<string[]>([]);
    const [selectedOption, setSelectedOption] = useState<string | null>(null);
    const [isCorrect, setIsCorrect] = useState<boolean | null>(null);

    // The displayed word (either in the foreign language or the native language).
    const word = showWordInForeignLanguage ? wordItem.word : wordItem.translation;
    // The correct translation of the word.
    const correctTranslation = showWordInForeignLanguage ? wordItem.translation : wordItem.word;

    useEffect(() => {
        // Set up options with a method similar to Swift's `getSingleChoiceOptions`
        setOptions(generateOptions(wordItem, showWordInForeignLanguage, allWords));
        setSelectedOption(null);
        setIsCorrect(null);
    }, [wordItem, showWordInForeignLanguage, allWords]);

    function generateOptions(wordItem: WordItem, showWordInForeignLanguage: boolean, allWords: WordItem[]): string[] {
        const correctOption = showWordInForeignLanguage ? wordItem.translation : wordItem.word;
        const optionsSet = new Set([correctOption]);

        while (optionsSet.size < 4) {
            const randomOption = showWordInForeignLanguage
                ? allWords[Math.floor(Math.random() * allWords.length)].translation
                : allWords[Math.floor(Math.random() * allWords.length)].word;
            optionsSet.add(randomOption);
        }

        return Array.from(optionsSet).sort(() => Math.random() - 0.5);
    }

    const handleSelectOption = (option: string) => {
        setSelectedOption(option);
        const correct = option === correctTranslation;
        setIsCorrect(correct);

        markAsKnown(wordItem, LearningMode.SingleChoice, correct);

        if (correct) {
            nextWord();
        }
    };

    const handleKeyPress = useCallback(
        (event: KeyboardEvent) => {
            if (event.key === 'Enter' && isCorrect === false) {
                nextWord();
            } else if (event.key >= '1' && event.key <= '4' && !selectedOption) {
                const index = parseInt(event.key, 10) - 1;
                if (options[index]) handleSelectOption(options[index]);
            }
        },
        [nextWord, selectedOption, options, isCorrect]
    );

    useEffect(() => {
        window.addEventListener('keydown', handleKeyPress);
        return () => window.removeEventListener('keydown', handleKeyPress);
    }, [handleKeyPress]);

    return {
        word,
        correctTranslation,
        options,
        selectedOption,
        isCorrect,
        handleSelectOption,
        handleKeyPress,
        nextWord,
    };
}

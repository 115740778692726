// ./domain/interfaces/CreateFillBlanksInteractor.ts
import { CreateFillBlanksService } from "../interfaces/CreateFillBlanksService";
import { SpeechGenerating } from "../interfaces/SpeechGenerating";
import { AudioUploading } from "../interfaces/AudioUploading";
import { FillBlanksStorage } from "../interfaces/FillBlanksStorage";

export interface CreateFillBlanksInteractor {
    createText(words: string, selectedRequester: string): Promise<string>;
    confirm(name: string, text: string): Promise<void>;
}

export class CreateFillBlanksInteractorImpl implements CreateFillBlanksInteractor {
    private textGenerator: CreateFillBlanksService;
    private speechGenerator: SpeechGenerating;
    private audioUploader: AudioUploading;
    private storage: FillBlanksStorage;

    constructor(
        textGenerator: CreateFillBlanksService,
        speechGenerator: SpeechGenerating,
        audioUploader: AudioUploading,
        storage: FillBlanksStorage
    ) {
        this.textGenerator = textGenerator;
        this.speechGenerator = speechGenerator;
        this.audioUploader = audioUploader;
        this.storage = storage;
    }

    async createText(words: string, selectedRequester: string): Promise<string> {
        const createdText = await this.textGenerator.createText(words, selectedRequester);
        return createdText;
    }

    async confirm(name: string, text: string): Promise<void> {
        const modifiedText = text.replace(/\[|\]/g, '');

        const audioData = await this.speechGenerator.generateSpeech(modifiedText);
        const fileName = crypto.randomUUID();
        const audioURL = await this.audioUploader.uploadAudio(audioData, fileName);
        await this.storage.saveFillBlanksExercise(name, text, audioURL);
    }
}
// ExerciseSelectOptionPreview.tsx
import React from 'react';
import {ExerciseSelectOption} from "../../../../domain/models";

interface ExerciseSelectOptionPreviewProps {
    exercise: ExerciseSelectOption;
}

export const ExerciseSelectOptionPreview: React.FC<ExerciseSelectOptionPreviewProps> = ({
                                                                                            exercise,
                                                                                        }) => {
    return (
        <div style={{ border: '1px solid #ccc', padding: '8px', maxWidth: '600px' }}>
            <p>
                <strong>Sentence:</strong> {exercise.sentence}
            </p>
            <ul>
                <li>{exercise.option1}</li>
                <li>{exercise.option2}</li>
                <li>{exercise.option3}</li>
                <li>{exercise.option4}</li>
            </ul>
            <p>
                <strong>Correct Option:</strong> {exercise.correctOption}
            </p>
        </div>
    );
};

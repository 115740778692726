// ui/components/learning/modes/add_picture/AddPictureView.tsx
import React from 'react';
import {LearningMode, WordItem} from "../../../../../domain/models";

interface AddPictureViewProps {
    wordItem: WordItem;
    markAsKnown: (word: WordItem, mode: LearningMode, success: boolean) => void;
    nextWord: () => void;
}

export const AddPictureView: React.FC<AddPictureViewProps> = ({
                                                                  wordItem,
                                                                  markAsKnown,
                                                                  nextWord,
                                                              }) => {
    const handleAddPicture = () => {
        // Logic to add picture
        markAsKnown(wordItem, LearningMode.AddPicture, true);
        nextWord();
    };

    return (
        <div>
            <h2>Add a Picture for {wordItem.id}</h2>
            {/* Display UI to add a picture */}
            <button onClick={handleAddPicture}>Add Picture</button>
        </div>
    );
};

// src/ui/components/exercise/ExercisesList.tsx

import React, { useState, useEffect } from 'react';
import { ExerciseSet } from '../../../domain/models/ExerciseSet';
// import { CompositionRoot } from '../../../compositionRoot';
import { useNavigate } from 'react-router-dom';
import CompositionRoot from "../../../compositionRoot";

const ExercisesList: React.FC = () => {
    const [exercisesIds, setExercisesIds] = useState<string[]>([]);
    const [exercises, setExercises] = useState<{ [id: string]: ExerciseSet | null }>({});
    const userManager = CompositionRoot.getAppUserManager();
    const storage = CompositionRoot.exercisesStorage;
    const navigate = useNavigate();

    useEffect(() => {
        const fetchUserExercises = async () => {
            const currentUser = userManager.currentUser;
            if (currentUser && currentUser.exerciseSets) {
                setExercisesIds(currentUser.exerciseSets);
                const initialExercises: { [id: string]: ExerciseSet | null } = {};
                currentUser.exerciseSets.forEach((id: string) => {
                    initialExercises[id] = null;
                });
                setExercises(initialExercises);
                currentUser.exerciseSets.forEach((id: string) => {
                    loadData(id);
                });
            }
        };

        fetchUserExercises();
    }, [userManager.currentUser]);

    const loadData = async (id: string) => {
        try {
            console.log(`loadData getExerciseSet setId: ${id}`);
            const set = await storage.getExerciseSet(id);
            setExercises(prevExercises => ({
                ...prevExercises,
                [id]: set,
            }));
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <div style={{ padding: '16px' }}>
            <h2>Exercises List</h2>
            {exercisesIds.map(id => {
                const setName = exercises[id]?.name || id;
                return (
                    <button
                        key={id}
                        onClick={() => navigate(`/exercisePractice/${id}`)}
                        style={{
                            display: 'block',
                            margin: '8px 0',
                            padding: '8px 16px',
                            fontSize: '16px',
                            cursor: 'pointer',
                        }}
                    >
                        {setName}
                    </button>
                );
            })}
        </div>
    );
};

export default ExercisesList;

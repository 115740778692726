// src/ui/components/adding/AddMultipleWordsView.tsx

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BaseButton from '../BaseButton';
import CompositionRoot from '../../../compositionRoot';

const AddMultipleWordsView: React.FC = () => {
    const [text, setText] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const wordParser = CompositionRoot.getWordParser();

    const parseAndShowWords = async () => {
        if (!text.trim()) return;
        setIsLoading(true);

        try {
            const words = await wordParser.parse(text); // Используем WordParser напрямую
            navigate('/addManually', { state: { words } });
        } catch (error) {
            console.error("Error parsing words:", error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div style={{ padding: '20px', textAlign: 'center', maxWidth: '600px', margin: '0 auto' }}>
            <h2>Paste words to translate</h2>
            <textarea
                value={text}
                onChange={(e) => setText(e.target.value)}
                placeholder="..."
                style={{ width: '100%', height: '150px', padding: '10px', fontSize: '16px' }}
            />
            {isLoading ? (
                <p>Loading...</p>
            ) : (
                <BaseButton color="#007bff" onClick={parseAndShowWords}>
                    Translate
                </BaseButton>
            )}
        </div>
    );
};

export default AddMultipleWordsView;

import React, { useState } from 'react';

interface ButtonProps {
    color?: string;
    onClick: () => void;
    children: React.ReactNode;
}

const BaseButton: React.FC<ButtonProps> = ({ color = '#007bff', onClick, children }) => {
    const [isHovered, setIsHovered] = useState(false);
    const [isPressed, setIsPressed] = useState(false);

    const handleMouseEnter = () => setIsHovered(true);
    const handleMouseLeave = () => {
        setIsHovered(false);
        setIsPressed(false); // Сброс нажатия при уходе мыши
    };
    const handleMouseDown = () => setIsPressed(true);
    const handleMouseUp = () => setIsPressed(false);

    // Определение стиля в зависимости от состояния
    const currentStyle = isPressed
        ? activeStyle(color)
        : isHovered
            ? hoverStyle(color)
            : buttonStyle(color);

    return (
        <button
            onClick={onClick}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
            style={currentStyle}
        >
            {children}
        </button>
    );
};

// Базовый стиль кнопки
const buttonStyle = (color: string): React.CSSProperties => ({
    color: '#ffffff',
    backgroundColor: color,
    fontSize: '18px',
    padding: '10px 20px',
    border: 'none',
    borderRadius: '8px',
    cursor: 'pointer',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
    transition: 'transform 0.2s ease, box-shadow 0.2s ease',
    fontWeight: 'bold',
    textAlign: 'center',
});

// Стиль при наведении
const hoverStyle = (color: string): React.CSSProperties => ({
    ...buttonStyle(color),
    backgroundColor: darkenColor(color, 0.1),
    boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.3)',
});

// Стиль при нажатии
const activeStyle = (color: string): React.CSSProperties => ({
    ...buttonStyle(color),
    transform: 'scale(0.95)',
    backgroundColor: darkenColor(color, 0.15),
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
});

// Функция для затемнения цвета
const darkenColor = (color: string, amount: number): string => {
    const colorValue = parseInt(color.slice(1), 16);
    const r = Math.max(0, (colorValue >> 16) - Math.round(255 * amount));
    const g = Math.max(0, ((colorValue >> 8) & 0x00ff) - Math.round(255 * amount));
    const b = Math.max(0, (colorValue & 0x0000ff) - Math.round(255 * amount));
    return `#${(r << 16 | g << 8 | b).toString(16).padStart(6, '0')}`;
};

export default BaseButton;

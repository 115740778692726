// src/data/implementations/OpenAIRequester.ts

interface OpenAIResponse<T> {
    choices: Array<{
        finish_reason: string;
        message: {
            function_call?: {
                name: string;
                arguments: string;
            };
        };
    }>;
}

export class OpenAIRequester {
    private apiKey: string;
    private apiUrl: string = 'https://api.openai.com/v1/chat/completions';
    private model: string = 'gpt-4o-2024-08-06';
    // private model: string = 'gpt-4o-2024-11-20';

    public constructor(apiKey: string) {
        this.apiKey = apiKey;
    }

    private generateJsonSchema<T>(example: T): any {
        const properties: { [key: string]: any } = {};
        const required: string[] = [];

        for (const key in example) {
            if (Object.prototype.hasOwnProperty.call(example, key)) {
                const value = example[key];
                let type = 'string'; // Default to string; you can expand this to handle other types

                // You can add more type checks here if needed
                if (typeof value === 'number') {
                    type = 'number';
                } else if (typeof value === 'boolean') {
                    type = 'boolean';
                }

                properties[key] = { type };
                required.push(key);
            }
        }

        return {
            type: 'object',
            properties: {
                translations: {
                    type: 'array',
                    items: {
                        type: 'object',
                        properties,
                        required,
                        additionalProperties: false,
                    },
                },
            },
            required: ['translations'],
            additionalProperties: false,
        };
    }

    public async requestWithCustomFormat<T>(prompt: string, example: T): Promise<T[]> {
        const url = this.apiUrl;
        const headers = {
            'Authorization': `Bearer ${this.apiKey}`,
            'Content-Type': 'application/json',
        };

        const messages = [
            { role: 'system', content: 'You are a helpful assistant.' },
            { role: 'user', content: prompt },
        ];

        const jsonSchema = this.generateJsonSchema(example);

        const responseFormat = {
            type: 'json_schema',
            json_schema: {
                name: 'custom_response',
                strict: true,
                schema: jsonSchema,
            },
        };

        const parameters = {
            model: this.model,
            messages: messages,
            max_tokens: 1500,
            temperature: 0.2,
            response_format: responseFormat,
        };

        const response = await fetch(url, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(parameters),
        });

        if (!response.ok) {
            throw new Error(`OpenAI API request failed with status ${response.status}: ${response.statusText}`);
        }

        const data = await response.json();

        console.log('Received JSON:', data);

        const choices = data.choices;
        if (!choices || choices.length === 0) {
            throw new Error('No choices returned from OpenAI API');
        }

        const message = choices[0].message;
        const content = message.content;
        if (!content) {
            throw new Error('No content in the assistant response');
        }

        let responseJson;
        try {
            responseJson = JSON.parse(content);
        } catch (error) {
            throw new Error('Failed to parse assistant response as JSON');
        }

        const translations: T[] = responseJson.translations;
        if (!translations) {
            throw new Error('No translations in response');
        }

        return translations;
    }
}

// ./src/data/implementations/FirestoreExercisesService.ts
import { ExercisesStorage } from '../../domain/interfaces/ExercisesStorage';
import { db } from '../config/firebase';
import { doc, setDoc, getDoc, collection, addDoc, Timestamp, getDocs, QuerySnapshot } from 'firebase/firestore';
import {Exercise, ExerciseSet} from "../../domain/models";

export class FirestoreExercisesService implements ExercisesStorage {
    constructor() {}

    /**
     * Сохраняет набор упражнений в Firestore.
     * @param exerciseSet Набор упражнений для сохранения.
     */
    public async saveExerciseSet(exerciseSet: ExerciseSet): Promise<void> {
        if (!exerciseSet.authorId) {
            throw new Error('Author ID is required to save ExerciseSet');
        }

        const exerciseSetRef = doc(db, 'exerciseSets', exerciseSet.id);

        // Подготовка данных для основного документа (метаданные сета)
        const exerciseSetData = {
            id: exerciseSet.id,
            creationDate: Timestamp.fromDate(new Date()),
            authorId: exerciseSet.authorId,
            name: exerciseSet.name || '',
        };

        // Сохраняем основной документ
        await setDoc(exerciseSetRef, exerciseSetData);

        // Сохраняем упражнения как поддокументы в коллекции "exercises"
        const exercisesCollectionRef = collection(exerciseSetRef, 'exercises');
        for (const exercise of exerciseSet.exercises) {
            const exerciseData = this.serializeExercise(exercise);
            await setDoc(doc(exercisesCollectionRef, exercise.id), exerciseData);
        }
    }

    /**
     * Получает набор упражнений из Firestore по ID.
     * @param id ID набора упражнений.
     * @returns Набор упражнений.
     */
    public async getExerciseSet(id: string): Promise<ExerciseSet> {
        const exerciseSetRef = doc(db, 'exerciseSets', id);
        const exerciseSetDoc = await getDoc(exerciseSetRef);

        if (!exerciseSetDoc.exists()) {
            throw new Error(`ExerciseSet not found for ID: ${id}`);
        }

        const exerciseSetData = exerciseSetDoc.data();
        const name = exerciseSetData.name || id;

        // Получаем подколлекцию "exercises"
        const exercisesCollectionRef = collection(exerciseSetRef, 'exercises');
        const exercisesSnapshot: QuerySnapshot = await getDocs(exercisesCollectionRef);

        const exercises: Exercise[] = exercisesSnapshot.docs.map((docSnapshot) => {
            const data = docSnapshot.data();
            return this.deserializeExercise(data, docSnapshot.id);
        }).filter((exercise): exercise is Exercise => exercise !== null);

        return {
            id: exerciseSetData.id,
            exercises: exercises,
            authorId: exerciseSetData.authorId,
            name: name,
        };
    }

    /**
     * Сериализует упражнение для сохранения в Firestore.
     * @param exercise Упражнение для сериализации.
     * @returns Объект для сохранения в Firestore.
     */
    private serializeExercise(exercise: Exercise): any {
        switch (exercise.type.kind) {
            case 'missingWord':
                return {
                    type: 'missingWord',
                    sentence: exercise.type.data.sentence,
                    correctForm: exercise.type.data.correctForm,
                };
            case 'selectOption':
                return {
                    type: 'selectOption',
                    sentence: exercise.type.data.sentence,
                    correctOption: exercise.type.data.correctOption,
                    option1: exercise.type.data.option1,
                    option2: exercise.type.data.option2,
                    option3: exercise.type.data.option3,
                    option4: exercise.type.data.option4,
                };
            default:
                throw new Error('Unknown ExerciseType kind');
        }
    }

    /**
     * Десериализует данные из Firestore в объект упражнения.
     * @param data Данные упражнения из Firestore.
     * @param id ID упражнения.
     * @returns Упражнение или null, если данные некорректны.
     */
    private deserializeExercise(data: any, id: string): Exercise | null {
        const type = data.type;
        if (!type) return null;

        switch (type) {
            case 'missingWord': {
                const {sentence, correctForm} = data;
                if (
                    typeof sentence !== 'string' ||
                    typeof correctForm !== 'string'
                ) {
                    return null;
                }
                return {
                    id: id,
                    type: {
                        kind: 'missingWord',
                        data: {
                            sentence,
                            correctForm,
                        },
                    },
                };
            }
            case 'selectOption': {
                const {sentence, correctOption, option1, option2, option3, option4} = data;
                if (
                    typeof sentence !== 'string' ||
                    typeof correctOption !== 'string' ||
                    typeof option1 !== 'string' ||
                    typeof option2 !== 'string' ||
                    typeof option3 !== 'string' ||
                    typeof option4 !== 'string'
                ) {
                    return null;
                }
                return {
                    id: id,
                    type: {
                        kind: 'selectOption',
                        data: {
                            sentence,
                            correctOption,
                            option1,
                            option2,
                            option3,
                            option4,
                        },
                    },
                };
            }
            default:
                return null;
        }
    }
}

// src/components/StartScreenMacOS.tsx
import React from 'react';
import MainContent from '../MainContent';
import { AuthProvider } from '../../context/AuthContext'; // Mock context

const StartScreenMacOS: React.FC = () => {
    return (
        <AuthProvider>
            <div style={{ display: 'flex', minWidth: '800px', minHeight: '600px' }}>
                <main style={{ width: '100%', padding: '20px' }}>
                    <MainContent />
                </main>
            </div>
        </AuthProvider>
    );
};

export default StartScreenMacOS;

// src/ui/components/exercise/create_exercise/EditExerciseItemView.tsx
import React from 'react';
import { ExerciseMissingWordEditView } from './ExerciseMissingWordEditView';
import { ExerciseMissingWordPreview } from './ExerciseMissingWordPreview';
import { ExerciseSelectOptionEditView } from './ExerciseSelectOptionEditView';
import { ExerciseSelectOptionPreview } from './ExerciseSelectOptionPreview';
import {Exercise} from "../../../../domain/models";

interface EditExerciseItemViewProps {
    exercise: Exercise;
    setExercise: (exercise: Exercise) => void;
    onDelete: () => void;
}

export const EditExerciseItemView: React.FC<EditExerciseItemViewProps> = ({
                                                                              exercise,
                                                                              setExercise,
                                                                              onDelete,
                                                                          }) => {
    switch (exercise.type.kind) {
        case 'missingWord':
            return (
                <div style={{ display: 'flex', padding: '8px 0' }}>
                    <ExerciseMissingWordEditView
                        exercise={exercise.type.data}
                        setExercise={(data) =>
                            setExercise({ ...exercise, type: { kind: 'missingWord', data } })
                        }
                        onDelete={onDelete}
                    />
                    <ExerciseMissingWordPreview exercise={exercise.type.data} />
                </div>
            );
        case 'selectOption':
            return (
                <div style={{ display: 'flex', padding: '8px 0' }}>
                    <ExerciseSelectOptionEditView
                        exercise={exercise.type.data}
                        setExercise={(data) =>
                            setExercise({ ...exercise, type: { kind: 'selectOption', data } })
                        }
                        onDelete={onDelete}
                    />
                    <ExerciseSelectOptionPreview exercise={exercise.type.data} />
                </div>
            );
        default:
            return null;
    }
};

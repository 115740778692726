// ui/components/learning/modes/standard/StandardLearnView.tsx
import React from 'react';
import { BaseLearnView } from './BaseLearnView';
import { WordItem, LearningMode } from '../../../../../domain/models';

interface StandardLearnViewProps {
    wordItem: WordItem;
    markAsKnown: (word: WordItem, mode: LearningMode, success: boolean) => void;
    nextWord: () => void;
}

export const StandardLearnView: React.FC<StandardLearnViewProps> = ({
                                                                        wordItem,
                                                                        markAsKnown,
                                                                        nextWord
                                                                    }) => {

    const handleMarkAsKnown = (success: boolean) => {
        markAsKnown(wordItem, LearningMode.Standard, success);
        nextWord();
    };

    return (
        <BaseLearnView
            word={wordItem.word}
            translation={wordItem.translation}
            onMarkAsKnown={handleMarkAsKnown}
            onNextWord={nextWord}
        />
    );
};

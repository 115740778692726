// src/components/adding/AddWordsByThemeView.tsx
import React, { useState } from 'react';
import BaseButton from '../BaseButton';

interface AddWordsByThemeViewProps {
    onFetchWordsByTheme: (theme: string) => void;
    isLoading: boolean;
    errorMessage?: string | null; // Добавлено null в тип
}

const AddWordsByThemeView: React.FC<AddWordsByThemeViewProps> = ({ onFetchWordsByTheme, isLoading, errorMessage }) => {
    const [theme, setTheme] = useState("");

    return (
        <div style={{ padding: '20px', textAlign: 'center', maxWidth: '600px', margin: '0 auto' }}>
            <h2>Add Words by Topic</h2>
            <input
                type="text"
                value={theme}
                onChange={(e) => setTheme(e.target.value)}
                placeholder="Enter Topic"
                style={{ width: '100%', padding: '10px', fontSize: '16px', borderRadius: '5px', border: '1px solid #ccc' }}
            />
            {isLoading ? (
                <p>Loading...</p>
            ) : (
                <BaseButton color="#007bff" onClick={() => onFetchWordsByTheme(theme)}>
                    Get Words by Topic
                </BaseButton>
            )}
            {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
        </div>
    );
};

export default AddWordsByThemeView;

// ExerciseSelectOptionEditView.tsx
import React from 'react';
import {ExerciseSelectOption} from "../../../../domain/models";

interface ExerciseSelectOptionEditViewProps {
    exercise: ExerciseSelectOption;
    setExercise: (exercise: ExerciseSelectOption) => void;
    onDelete: () => void;
}

export const ExerciseSelectOptionEditView: React.FC<ExerciseSelectOptionEditViewProps> = ({
                                                                                              exercise,
                                                                                              setExercise,
                                                                                              onDelete,
                                                                                          }) => {
    const handleChange = (field: keyof ExerciseSelectOption, value: string) => {
        setExercise({ ...exercise, [field]: value });
    };

    return (
        <div style={{ maxWidth: '600px', border: '1px solid #ccc', padding: '8px' }}>
            <div>
                <label>Sentence</label>
                <input
                    type="text"
                    value={exercise.sentence}
                    onChange={(e) => handleChange('sentence', e.target.value)}
                />
            </div>
            <div>
                <label>Option 1</label>
                <input
                    type="text"
                    value={exercise.option1}
                    onChange={(e) => handleChange('option1', e.target.value)}
                />
            </div>
            <div>
                <label>Option 2</label>
                <input
                    type="text"
                    value={exercise.option2}
                    onChange={(e) => handleChange('option2', e.target.value)}
                />
            </div>
            <div>
                <label>Option 3</label>
                <input
                    type="text"
                    value={exercise.option3}
                    onChange={(e) => handleChange('option3', e.target.value)}
                />
            </div>
            <div>
                <label>Option 4</label>
                <input
                    type="text"
                    value={exercise.option4}
                    onChange={(e) => handleChange('option4', e.target.value)}
                />
            </div>
            <div>
                <label>Correct Option</label>
                <input
                    type="text"
                    value={exercise.correctOption}
                    onChange={(e) => handleChange('correctOption', e.target.value)}
                />
            </div>
            <button onClick={onDelete} style={{ color: 'red', marginTop: '8px' }}>
                Delete
            </button>
        </div>
    );
};

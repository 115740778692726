// ui/components/learning/modes/single_choice/SingleChoiceView.tsx
import React from 'react';

interface SingleChoiceViewProps {
    word: string;
    correctTranslation: string;
    options: string[];
    selectedOption: string | null;
    isCorrect: boolean | null;
    onSelectOption: (option: string) => void;
    onNextPress: () => void;
}

export const SingleChoiceView: React.FC<SingleChoiceViewProps> = ({
                                                                      word,
                                                                      correctTranslation,
                                                                      options,
                                                                      selectedOption,
                                                                      isCorrect,
                                                                      onSelectOption,
                                                                      onNextPress,
                                                                  }) => {
    const getBackgroundColor = (option: string) => {
        if (selectedOption) {
            if (selectedOption === option) {
                return isCorrect ? 'green' : 'red';
            } else if (!isCorrect && option === correctTranslation) {
                return 'green';
            }
        }
        return 'blue';
    };

    return (
        <div style={{ textAlign: 'center', padding: '20px' }}>
            <h1>{word}</h1>

            <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', marginTop: '20px' }}>
                {options.map((option, index) => (
                    <button
                        key={index}
                        onClick={() => onSelectOption(option)}
                        disabled={selectedOption !== null}
                        style={{
                            padding: '10px',
                            borderRadius: '8px',
                            backgroundColor: getBackgroundColor(option),
                            color: 'white',
                            fontSize: '16px',
                            cursor: 'pointer',
                            width: '200px',
                            maxWidth: '400px',
                            margin: '0 auto',
                        }}
                    >
                        {option}
                    </button>
                ))}
            </div>

            {selectedOption && isCorrect === false && (
                <button
                    onClick={onNextPress}
                    style={{
                        marginTop: '20px',
                        padding: '10px 20px',
                        backgroundColor: 'blue',
                        color: 'white',
                        borderRadius: '8px',
                        cursor: 'pointer',
                        fontSize: '16px',
                    }}
                >
                    Next
                </button>
            )}
        </div>
    );
};

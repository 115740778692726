// src/data/implementations/FirestoreWordsService.ts

import { initializeApp } from 'firebase/app';
import {
    getFirestore, Firestore, collection, doc, setDoc, getDocs, writeBatch, deleteDoc, onSnapshot, updateDoc,
    serverTimestamp, arrayUnion, QuerySnapshot, DocumentData, DocumentSnapshot, Unsubscribe
} from 'firebase/firestore';
import { BehaviorSubject, Observable } from 'rxjs';
import { WordItem } from '../../domain/models/WordItem';
import { WordsServiceProtocol } from '../../domain/interfaces/WordsServiceProtocol';
import { LearningMode, WordState, WordItemModel } from '../../domain/models';
import { Repetition } from '../../domain/models/Repetition';
import CompositionRoot from '../../compositionRoot';

class FirestoreWordsService implements WordsServiceProtocol {
    private static instance: FirestoreWordsService;
    private db: Firestore;
    private wordsSubject: BehaviorSubject<WordItem[]> = new BehaviorSubject<WordItem[]>([]);
    private userId: string | null = null;
    private unsubscribeSnapshot: Unsubscribe | null = null;

    // Singleton pattern to ensure a single instance
    private constructor(userId: string | null = null) {
        this.db = getFirestore(); // Use getFirestore() instead of firebase.firestore()
        this.userId = userId;
        this.initializeUser();
    }

    public static getInstance(userId: string | null = null): FirestoreWordsService {
        if (!FirestoreWordsService.instance) {
            FirestoreWordsService.instance = new FirestoreWordsService(userId);
        }
        return FirestoreWordsService.instance;
    }

    public wordsService(forUserId: string): WordsServiceProtocol {
        return new FirestoreWordsService(forUserId);
    }

    // Initialize user and set up listener
    private initializeUser() {
        if (!this.userId) {
            const appUserManager = CompositionRoot.getAppUserManager();
            this.userId = appUserManager.currentUser?.uid || null;

            appUserManager.currentUserPublisher().subscribe(user => {
                this.userId = user?.uid || null;
                this.fetchWords();
            });
        }
        this.fetchWords();
    }

    // wordsPublisher: Observable<WordItem[]>
    public get wordsPublisher(): Observable<WordItem[]> {
        return this.wordsSubject.asObservable();
    }

    // Fetch words from Firestore
    private fetchWords() {
        if (this.unsubscribeSnapshot) {
            this.unsubscribeSnapshot();
            this.unsubscribeSnapshot = null;
        }

        if (!this.userId) {
            this.wordsSubject.next([]);
            return;
        }

        const wordsCollectionRef = collection(this.db, 'users', this.userId, 'words');

        this.unsubscribeSnapshot = onSnapshot(wordsCollectionRef, (snapshot: QuerySnapshot<DocumentData>) => {
            const words = snapshot.docs.map(doc => this.wordFromDocument(doc));
            this.wordsSubject.next(words);
        }, (error) => {
            console.error('Error fetching words:', error);
        });
    }

    // Helper method to convert Firestore document to WordItem
    private wordFromDocument(doc: DocumentSnapshot<DocumentData>): WordItem {
        const data = doc.data();
        if (!data) {
            return {
                id: doc.id,
                word: '',
                translation: '',
                example: '',
                imageURL: '',
                repetitions: [],
            };
        }
        return {
            id: doc.id,
            word: data.word || '',
            translation: data.translation || '',
            example: data.example || '',
            imageURL: data.imageURL || '',
            repetitions: data.repetitions || [],
        };
    }

    // Helper method to convert WordItem to Firestore document data
    private wordToDocument(word: WordItem): any {
        return {
            word: word.word,
            translation: word.translation,
            example: word.example,
            imageURL: word.imageURL,
            repetitions: word.repetitions,
        };
    }

    // loadWords(): WordItem[]
    public loadWords(): WordItem[] {
        return this.wordsSubject.getValue();
    }

    // saveWord(word: WordItem): Promise<void>
    public async saveWord(word: WordItem): Promise<void> {
        if (!this.userId) return;

        try {
            const wordDocRef = doc(this.db, 'users', this.userId, 'words', word.id);
            await setDoc(wordDocRef, this.wordToDocument(word));
        } catch (error) {
            console.error('Error saving word:', error);
        }
    }

    // markAsKnown(word: WordItem, mode: LearningMode, success: boolean): Promise<void>
    public async markAsKnown(word: WordItem, mode: LearningMode, success: boolean): Promise<void> {
        const words = this.loadWords();
        const index = words.findIndex(w => w.id === word.id);
        if (index !== -1) {
            const updatedWord = { ...words[index] }; // create a copy
            const repetition: Repetition = {
                date: new Date(),
                type: mode,
                success: success,
            };
            updatedWord.repetitions = [...updatedWord.repetitions, repetition];
            await this.saveWord(updatedWord);
            words[index] = updatedWord;
            this.wordsSubject.next([...words]);
        }
    }

    // addWords(words: WordItem[]): Promise<void>
    public async addWords(newWords: WordItem[]): Promise<void> {
        if (!this.userId) return;

        const batch = writeBatch(this.db);
        const wordsCollection = collection(this.db, 'users', this.userId, 'words');

        newWords.forEach(word => {
            const docRef = doc(wordsCollection, word.id);
            batch.set(docRef, this.wordToDocument(word));
        });

        try {
            await batch.commit();
            const currentWords = this.loadWords();
            this.wordsSubject.next([...currentWords, ...newWords]);
        } catch (error) {
            console.error('Error adding words:', error);
        }
    }

    // getWordsFor(mode: WordState | null): Promise<WordItem[]>
    public getWordsFor(mode: WordState | null): WordItem[] {
        const words = this.loadWords();

        if (mode === null) {
            return words.filter(word => WordItemModel.learningMode(word) !== WordState.Done);
        } else {
            return words.filter(word =>
                WordItemModel.learningMode(word) === mode &&
                !WordItemModel.isWaiting(word)
            );
        }
    }

    // clearLearnedWords(): Promise<void>
    public async clearLearnedWords(): Promise<void> {
        const words = this.loadWords();
        const batch = writeBatch(this.db);
        const wordsCollection = collection(this.db, 'users', this.userId!, 'words');

        words.forEach(word => {
            const updatedWord = { ...word, repetitions: [] };
            const docRef = doc(wordsCollection, word.id);
            batch.update(docRef, { repetitions: [] });
            // Update the word in the array
            const index = words.findIndex(w => w.id === word.id);
            if (index !== -1) {
                words[index] = updatedWord;
            }
        });

        try {
            await batch.commit();
            this.wordsSubject.next([...words]);
        } catch (error) {
            console.error('Error clearing learned words:', error);
        }
    }

    // clearAllWords(): Promise<void>
    public async clearAllWords(): Promise<void> {
        if (!this.userId) return;

        const wordsCollection = collection(this.db, 'users', this.userId, 'words');

        try {
            const snapshot = await getDocs(wordsCollection);
            const batch = writeBatch(this.db);
            snapshot.docs.forEach(doc => {
                batch.delete(doc.ref);
            });

            await batch.commit();
            this.wordsSubject.next([]);
        } catch (error) {
            console.error('Error clearing all words:', error);
        }
    }

    // deleteWord(word: WordItem): Promise<void>
    public async deleteWord(word: WordItem): Promise<void> {
        if (!this.userId) return;

        try {
            const wordDocRef = doc(this.db, 'users', this.userId, 'words', word.id);
            await deleteDoc(wordDocRef);

            const words = this.loadWords();
            const updatedWords = words.filter(w => w.id !== word.id);
            this.wordsSubject.next([...updatedWords]);
        } catch (error) {
            console.error('Error deleting word:', error);
        }
    }

    // addImage(url: string, word: WordItem): Promise<void>
    public async addImage(url: string, word: WordItem): Promise<void> {
        const words = this.loadWords();
        const index = words.findIndex(w => w.id === word.id);
        if (index !== -1) {
            const updatedWord = { ...words[index], imageURL: url };
            await this.saveWord(updatedWord);
            words[index] = updatedWord;
            this.wordsSubject.next([...words]);
        }
    }

    // delayWords(words: WordItem[]): Promise<void>
    public async delayWords(words: WordItem[]): Promise<void> {
        if (!this.userId) return;

        // Generate a new document ID for the set
        const newSetRef = doc(collection(this.db, 'wordsSets'));
        const newSetId = newSetRef.id;

        // Prepare the set metadata
        const setData = {
            authorId: this.userId,
            creationDate: serverTimestamp(),
        };

        try {
            // Save the set metadata
            await setDoc(newSetRef, setData);

            // Save each word in the words array to the new set's "words" sub-collection
            const batch = writeBatch(this.db);
            words.forEach(word => {
                const wordRef = doc(collection(newSetRef, 'words'), word.id);
                batch.set(wordRef, this.wordToDocument(word));
            });

            await batch.commit();

            // Add delayed set ID to the user document
            await this.addDelayedSetToUser(newSetId);
        } catch (error) {
            console.error('Error delaying words:', error);
        }
    }

    // Helper method to add delayed set ID to the user document
    private async addDelayedSetToUser(newSetId: string): Promise<void> {
        if (!this.userId) return;

        const userRef = doc(this.db, 'usersData', this.userId);
        try {
            await updateDoc(userRef, {
                delayedSets: arrayUnion(newSetId),
            });
        } catch (error) {
            console.error('Error updating user with delayed set ID:', error);
        }
    }
}

export default FirestoreWordsService;

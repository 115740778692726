// ui/components/learning/modes/exam/ExamLearnView.tsx
import React from 'react';
import { ExamTextInputView } from './ExamTextInputView';
import { LearningMode, WordItem } from '../../../../../domain/models';
import { useExamLearnViewModel } from './ExamLearnViewModel';

interface ExamLearnViewProps {
    wordItem: WordItem;
    markAsKnown: (word: WordItem, mode: LearningMode, success: boolean) => void;
    nextWord: () => void;
}

export const ExamLearnView: React.FC<ExamLearnViewProps> = ({
                                                                wordItem,
                                                                markAsKnown,
                                                                nextWord,
                                                            }) => {
    const viewModel = useExamLearnViewModel(wordItem, markAsKnown, nextWord);

    return (
        <div style={{ textAlign: 'center', padding: '20px' }}>
            <h2>Exam Mode</h2>
            {viewModel.showLearnedMessage && (
                <div style={{ color: 'green', fontWeight: 'bold', marginBottom: '10px' }}>
                    Ура, слово выучено!
                </div>
            )}
            <ExamTextInputView
                word={viewModel.word}
                inputText={viewModel.inputText}
                setInputText={viewModel.setInputText}
                onSubmit={viewModel.submitAnswer}
                hint={viewModel.hint}
            />
        </div>
    );
};

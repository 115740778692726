// ./ui/components/statistics/DifficultWordsView.tsx

import React, { useMemo } from 'react';
import WordsViewModel from '../../viewmodels/WordsViewModel';
import { WordItem } from '../../../domain/models/WordItem';
import { WordItemModel } from '../../../domain/models/WordItemModel';
import { useNavigate } from 'react-router-dom';

interface DifficultWordsViewProps {
    wordsViewModel: WordsViewModel;
}

const DifficultWordsView: React.FC<DifficultWordsViewProps> = ({ wordsViewModel }) => {
    const navigate = useNavigate();

    const difficultWords = useMemo(() => {
        return wordsViewModel.words
            .filter(word => WordItemModel.learningMode(word) !== 'done')
            .filter(word => WordItemModel.successRate(word) < 100)
            .sort((a, b) => b.repetitions.length - a.repetitions.length)
            .slice(0, 12)
            .sort((a, b) => WordItemModel.successRate(a) - WordItemModel.successRate(b));
    }, [wordsViewModel.words]);

    const wordItemView = (word: WordItem) => {
        const handleClick = () => {
            navigate(`/wordDetails/${word.id}`);
        };

        return (
            <div key={word.id} style={wordItemStyle} onClick={handleClick}>
                <div style={{ fontWeight: 'bold', fontSize: '1rem', color: '#FFFFFF' }}>{word.word}</div>
                <div style={{ color: '#DDDDDD', fontSize: '0.9rem' }}>{word.translation}</div>
                <div style={{ color: '#FF6666', fontSize: '0.8rem', marginTop: '5px' }}>
                    {`Success Rate: ${WordItemModel.successRate(word).toFixed(2)}%`}
                </div>
            </div>
        );
    };

    return (
        <div style={containerStyle}>
            <h2 style={{ marginBottom: '10px', color: '#FFFFFF' }}>Most Difficult Words</h2>
            <div style={gridContainerStyle}>
                {difficultWords.map(word => wordItemView(word))}
            </div>
        </div>
    );
};

// Обновленный стиль контейнера
const containerStyle: React.CSSProperties = {
    padding: '20px',
    backgroundColor: '#333333', // Темный фон контейнера
    borderRadius: '10px',
    marginBottom: '20px',
};

// Обновленный стиль сетки
const gridContainerStyle: React.CSSProperties = {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(150px, 1fr))',
    gap: '20px',
};

// Обновленный стиль элемента слова
const wordItemStyle: React.CSSProperties = {
    padding: '10px',
    backgroundColor: '#444444', // Темный фон для элементов слова
    borderRadius: '8px',
    cursor: 'pointer',
    color: '#FFFFFF', // Светлый цвет текста
};

export default DifficultWordsView;

// src/domain/models/LearningMode.ts

export enum LearningMode {
    Standard = "Стандартный",
    AddPicture = "Выбор изображения",
    MultipleChoice = "Множественный выбор",
    SingleChoice = "Выбор из нескольких",
    TextInput = "Ввод текста",
    SelectPicture = "Выбор картинки",
    Exam = "Контрольный",
    Done = "",
    AddingSimilar = "Добавление похожих"
}

// src/domain/models/WordState.ts

export enum WordState {
    Start = "start",
    Learn = "learn",
    Exam = "exam",
    Done = "done"
}

// Определение методов, аналогичных Swift'овскому enum
export namespace LearningMode {
    // Получение массива режимов обучения
    export const learningModes: LearningMode[] = [
        LearningMode.MultipleChoice,
        LearningMode.SingleChoice,
        LearningMode.TextInput,
        LearningMode.SelectPicture
    ];

    // Определение состояния слова в зависимости от режима
    export function wordState(mode: LearningMode): WordState {
        switch (mode) {
            case LearningMode.Standard:
            case LearningMode.AddPicture:
                return WordState.Start;
            case LearningMode.Exam:
                return WordState.Exam;
            case LearningMode.Done:
                return WordState.Done;
            default:
                return WordState.Learn;
        }
    }

    // Получение идентификатора режима
    export function id(mode: LearningMode): string {
        return mode.toString();
    }
}

// Расширение WordState для добавления методов
export namespace WordState {
    // Определение количества попыток для перехода на следующий уровень
    export function attemptsToMove(state: WordState): number {
        switch (state) {
            case WordState.Start:
                return 1;
            case WordState.Learn:
                return 4;
            case WordState.Exam:
                return 4;
            case WordState.Done:
                return 0;
            default:
                return 0;
        }
    }

    // Получение идентификатора состояния
    export function id(state: WordState): string {
        return state.toString();
    }
}

// ./ui/viewmodels/WordsViewModel.ts
import { WordItem } from '../../domain/models/WordItem';
import { WordsServiceProtocol } from '../../domain/interfaces/WordsServiceProtocol';
import {Observable, Subscription} from 'rxjs';
import {LearningMode, WordItemModel, WordState} from "../../domain/models";

class WordsViewModel {
    words: WordItem[] = [];
    private wordsService: WordsServiceProtocol;
    private subscription: Subscription | null = null;

    constructor(wordsService: WordsServiceProtocol) {
        this.wordsService = wordsService;
        this.subscribeToWords();
    }

    private subscribeToWords() {
        this.subscription = this.wordsService.wordsPublisher.subscribe(words => {
            this.words = words;
        });
    }

    public subscribeToWordsUpdates(): Observable<WordItem[]> {
        return this.wordsService.wordsPublisher;
    }

    addWords(newWords: WordItem[]): void {
        const wordsToAdd = newWords
            .filter(word => word.word.trim() !== '' && word.translation.trim() !== '') // Убираем пустые слова и переводы
            .filter(newWord => !this.words.some(existingWord => WordItemModel.isSimilar(existingWord, newWord))); // Убираем дубликаты

        if (wordsToAdd.length > 0) {
            this.wordsService.addWords(wordsToAdd);
        }
    }

    delayWords(newWords: WordItem[]): void {
        this.wordsService.delayWords(newWords);
    }

    get totalWords(): number {
        return this.words.length;
    }

    get learnedWords(): number {

        return this.words.filter(word => WordItemModel.learningMode(word) === 'done').length;
    }

    get learningWords(): number {
        return this.words.filter(word => WordItemModel.learningMode(word) !== 'done').length;
    }

    dispose() {
        this.subscription?.unsubscribe();
    }
}

export default WordsViewModel;

// ./data/implementations/FirestoreFillBlanksService.ts
import { FillBlanksStorage } from "../../domain/interfaces/FillBlanksStorage";
import { FillBlanksExerciseModel } from "../../domain/models/FillBlanksExerciseModel";
import { db } from "../config/firebase";
import {
    collection,
    addDoc,
    getDocs,
    getDoc,
    doc,
    Timestamp,
} from "firebase/firestore";
import CompositionRoot from "../../compositionRoot";

export class FirestoreFillBlanksService implements FillBlanksStorage {
    private collectionName = "fillBlanksExercises";
    private userId: string | null;

    constructor() {
        const appUserManager = CompositionRoot.getAppUserManager();
        this.userId = appUserManager.currentUser?.uid || null;
    }

    async saveFillBlanksExercise(name: string, text: string, audioURL: string): Promise<void> {
        const fillBlanksCollection = collection(db, this.collectionName);

        const data = {
            name,
            text,
            audioURL,
            creationDate: Timestamp.fromDate(new Date()),
            authorId: this.userId || "",
        };

        await addDoc(fillBlanksCollection, data);
    }

    async getFillBlanksExercises(): Promise<FillBlanksExerciseModel[]> {
        const fillBlanksCollection = collection(db, this.collectionName);
        const snapshot = await getDocs(fillBlanksCollection);
        const exercises: FillBlanksExerciseModel[] = [];

        snapshot.forEach((docSnapshot) => {
            const data = docSnapshot.data();
            const exercise = this.parseFillBlanksExercise(docSnapshot.id, data);
            if (exercise) {
                exercises.push(exercise);
            }
        });

        return exercises;
    }

    async getFillBlanksExercise(id: string): Promise<FillBlanksExerciseModel> {
        const documentRef = doc(db, this.collectionName, id);
        const document = await getDoc(documentRef);

        if (!document.exists()) {
            throw new Error("Exercise not found");
        }

        const data = document.data();
        const exercise = this.parseFillBlanksExercise(document.id, data);

        if (!exercise) {
            throw new Error("Failed to parse exercise");
        }

        return exercise;
    }

    private parseFillBlanksExercise(
        id: string,
        data: any
    ): FillBlanksExerciseModel | null {
        if (
            typeof data.name !== "string" ||
            typeof data.text !== "string" ||
            typeof data.audioURL !== "string" ||
            typeof data.authorId !== "string" ||
            !(data.creationDate instanceof Timestamp)
        ) {
            return null;
        }

        return {
            id,
            name: data.name,
            text: data.text,
            audioURL: data.audioURL,
            authorId: data.authorId,
            creationDate: data.creationDate.toDate(),
        };
    }
}

// src/domain/models/WordItemExtensions.ts
import { WordItem } from './WordItem';
import LearningStateMachine from './LearningStateMachine';
import {LearningMode, WordState} from "./LearningMode";

// Расширения для WordItem с помощью функций
export const WordItemModel = {
    // Метод для получения режима обучения
    learningMode(wordItem: WordItem): WordState {
        const stateMachine = new LearningStateMachine();
        wordItem.repetitions.forEach(repetition => stateMachine.attempt(repetition));
        return stateMachine.mode;
    },

    // Метод для проверки, нужно ли ожидание
    isWaiting(wordItem: WordItem): boolean {
        const stateMachine = new LearningStateMachine();
        wordItem.repetitions.forEach(repetition => stateMachine.attempt(repetition));
        return stateMachine.timeToWait > 0;
    },

    // Метод для получения текущего режима
    currMode(wordItem: WordItem): WordState | null {
        return WordItemModel.isWaiting(wordItem) ? null : WordItemModel.learningMode(wordItem);
    },

    // Метод для вычисления оставшихся попыток
    remainingAttempts(wordItem: WordItem): number {
        const stateMachine = new LearningStateMachine();
        wordItem.repetitions.forEach(repetition => stateMachine.attempt(repetition));
        return Math.max(WordState.attemptsToMove(stateMachine.mode) - stateMachine.successfulAttempts, 0);
    },

    // Метод для вычисления процента успеха
    successRate(wordItem: WordItem): number {
        const total = wordItem.repetitions.length;
        const successes = wordItem.repetitions.filter(repetition => repetition.success).length;
        return total === 0 ? 0 : (successes / total) * 100;
    },

    // Метод для определения даты завершения
    doneDate(wordItem: WordItem): Date | null {
        const stateMachine = new LearningStateMachine();
        wordItem.repetitions.forEach(repetition => stateMachine.attempt(repetition));
        return stateMachine.mode === WordState.Done ? new Date(wordItem.repetitions[wordItem.repetitions.length - 1].date) : null;
    },

    // Метод для проверки похожести двух слов
    isSimilar(wordItem: WordItem, newWord: WordItem): boolean {
        return (
            newWord.word.toLowerCase() === wordItem.word.toLowerCase() &&
            newWord.translation.toLowerCase() === wordItem.translation.toLowerCase()
        );
    },

    // Метод для проверки, стоит ли добавлять похожее слово
    shouldAddSimilar(wordItem: WordItem): boolean {
        const learnRepetitions = wordItem.repetitions.filter(repetition => LearningMode.wordState(repetition.type) !== WordState.Exam);
        if (learnRepetitions.length < 2) return false;

        const lastRepetitions = learnRepetitions.slice(-2);
        return lastRepetitions.every(repetition => !repetition.success);
    }
};

// src/ui/context/AuthContext.tsx
import React, { createContext, useContext, useEffect, useState } from 'react';
import CompositionRoot from '../../compositionRoot';
import {AppUser} from "../../domain/models";

interface AuthContextType {
    currentUser: AppUser | null;
    loading: boolean;
    login: (email: string, password: string) => Promise<void>;
    logout: () => Promise<void>;
    register: (email: string, password: string) => Promise<void>;
    signInWithGoogle: () => Promise<void>;
    signInWithApple: () => Promise<void>;
    signUpWithApple: () => Promise<void>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const authService = CompositionRoot.getAppUserManager(); // получение экземпляра authService через CompositionRoot
    const [currentUser, setCurrentUser] = useState<AppUser | null>(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Подписка на изменения состояния аутентификации
        const unsubscribe = authService.currentUserPublisher().subscribe((user) => {
            setCurrentUser(user);
            setLoading(false);
        });
        return () => unsubscribe.unsubscribe(); // Отписка при размонтировании компонента
    }, [authService]);

    const login = async (email: string, password: string) => {
        await authService.loginUser(email, password);
    };

    const logout = async () => {
        await authService.signOut();
    };

    const register = async (email: string, password: string) => {
        await authService.registerUser(email, password);
    };

    const signInWithGoogle = async () => {
        await authService.signInWithGoogle();
    };

    const signInWithApple = async () => {
        await authService.signInWithApple();
    };

    const signUpWithApple = async () => {
        await authService.signUpWithApple();
    };

    return (
        <AuthContext.Provider value={{ currentUser, loading, login, logout, register, signInWithGoogle, signInWithApple, signUpWithApple }}>
            {!loading && children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error("useAuth должен использоваться внутри AuthProvider");
    }
    return context;
};

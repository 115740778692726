// src/data/config/firebase.ts
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore"; // Импорт Firestore
import { firebaseConfig } from "./environment";

// Инициализируем Firebase
const app = initializeApp(firebaseConfig);

// Экспортируем аутентификацию для использования в компонентах
export const auth = getAuth(app);
export const db = getFirestore(app); // Экспорт Firestore

export default app;
// src/domain/models/AppUser.ts

// Импорт интерфейса
export interface AppUser {
    uid: string;
    email?: string | null;
    status?: string | null;
    trialStartDate?: Date | null;
    delayedSets: string[];
    exerciseSets: string[];
}

// Функция для создания AppUser из данных Firebase
export const fromDictionary = (data: Record<string, any>): AppUser | null => {
    const uid = data.uid as string;
    if (!uid) return null;

    const email = data.email as string | null;
    const status = data.status as string | null;
    const trialStartTimestamp = data.trialStartDate as number | undefined;
    const trialStartDate = trialStartTimestamp ? new Date(trialStartTimestamp * 1000) : null;
    const delayedSets = (data.delayedSets as string[]) || [];
    const exerciseSets = (data.exerciseSets as string[]) || [];

    return {
        uid,
        email,
        status,
        trialStartDate,
        delayedSets,
        exerciseSets
    };
};
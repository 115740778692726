// ./ui/components/exercise/fill_blanks/FillBlanksExerciseScreen.tsx
import React, { useState, useEffect } from 'react';
import FillInTheBlanksView from './FillInTheBlanksView';
import {useParams} from "react-router-dom";
import {FillBlanksStorage} from "../../../../domain/interfaces/FillBlanksStorage";
import CompositionRoot from "../../../../compositionRoot";
import {FillBlanksExerciseModel} from "../../../../domain/models/FillBlanksExerciseModel";
import "./FillBlanksExerciseScreen.css";

export const useFillBlanksExerciseScreenViewModel = (id: string) => {
    const [exercise, setExercise] = useState<FillBlanksExerciseModel | null>(null);
    const [audioURL, setAudioURL] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<Error | null>(null);

    const storage: FillBlanksStorage = CompositionRoot.fillBlanksStorage

    const loadData = async () => {
        try {
            setIsLoading(true);
            const exerciseData = await storage.getFillBlanksExercise(id);
            setExercise(exerciseData);
            setAudioURL(exerciseData.audioURL)
        } catch (err) {
            console.error(err);
            setError(err as Error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        loadData();
    }, [id]);

    return { exercise, audioURL, isLoading, error };
};


const FillBlanksExerciseScreen: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const vm = useFillBlanksExerciseScreenViewModel(id!);

    if (vm.isLoading) {
        return <div className="centered"><p>Loading...</p></div>;
    }

    if (vm.error) {
        return <div className="centered"><p>Error: {vm.error.message}</p></div>;
    }

    if (!vm.exercise) {
        return <div className="centered"><p>Exercise not found</p></div>;
    }

    return (
        <div className="exercise-container">
            <h2 className="exercise-title">{vm.exercise.name}</h2>
            {vm.audioURL && (
                <audio className="audio-player" controls src={vm.audioURL}>
                    Error with audio.
                </audio>
            )}
            <FillInTheBlanksView text={vm.exercise.text} />
        </div>
    );
};


export default FillBlanksExerciseScreen;
